import { URL_PARAM_ORDER, URL_PARAM_PAGE, URL_PARAM_PER_PAGE, URL_PARAM_SORT, URL_PARAM_TERM, URL_PARAM_TYPED } from './util/constants';

export interface IObjectKeys {
  [key: string]: string | number | boolean | object | object[] | undefined;
}

export enum HKAssetType {
  Photos = 'photos',
  Manuals = 'manuals',
  Videos = 'videos',
  Default = 'default'
}

export enum HKActionType {
  Add = 'add',
  Edit = 'edit'
}

export interface Contact {
  address1: string;
  address2: string;
  city: string;
  country: string;
  email: string;
  home_phone: string;
  id: number;
  image: string;
  image_id: number;
  mobile_phone: string;
  name: string;
  postal_code: string;
  state: string;
  type: string;
  type_id: number;
  work_phone: string;
}

export interface User {
  contact: Contact;
  id: number;
  is_admin: boolean;
  is_concierge: boolean;
  is_worker: boolean;
  login_email: string;
  name: string;
  timezone: string;
}

export interface Appointment extends AssetListElement {
  checked_out?: boolean;
  checked_out_by?: number;
  created: string;
  completed_tasks: number;
  description: string;
  duration: number;
  google_event_id: number;
  home_id: number;
  homeowner_notes: string;
  finished?: string | null;
  name: string;
  scheduled: string;
  worker?: Worker;
  type?: string;
  type_id?: number;
  // UI convenience properties
  display_date: string;
  display_time: string;
  worker_notes?: string;
}

export interface Asset {
  default: boolean;
  file_url: string;
  id?: number;
  name?: string;
  type: string;
  type_id?: number;
  cloud_store?: string | null;
  file_name?: string;
  opt_home_id?: number | null;
  thumb_cloud_store?: string | null;
}

export interface AssetListElement {
  id: number;
  image?: string;
  image_by_id?: number;
  assets: Asset[];
}

export interface Attribute extends IObjectKeys { }

export interface AttributeSchema {
  default: string;
  field: string;
  label: string;
  type: ValueType;
}

export enum ValueType {
  Number = 'number',
  String = 'string'
}

export interface Product extends AssetListElement {
  attributes: Attribute;
  description: string;
  home_id: number;
  id: number;
  image: string;
  is_generic?: boolean;
  name: string;
  product_template?: ProductModel;
  registered: string;
  serial: string;
  template_name?: string;
  type: string;
  type_id: number;
}

export interface ProductModel extends AssetListElement {
  attributes: Attribute;
  description: string;
  manufacturer: Manufacturer;
  model: string;
  name: string;
  type: string;
  type_id: number;
  type_data: ProductType;
}

export interface ProductType {
  [index: string] : string | number | AttributeSchema[];
  attribute_schema: AttributeSchema[];
  product_attribute_schema: AttributeSchema[];
  id: number;
  field: string;
  label: string;
}

export interface Manufacturer {
  attributes: Attribute;
  id: number,
  image: string;
  name: string;
  type: string;
  type_id: number;
}

export interface Task extends AssetListElement {
  appointment_id: number;
  assets: Asset[];
  finished?: string;
  id: number;
  name: string;
  statuses?: StatusTag[]
  tag_ids?: number[];
  type?: string;
  type_id?: number;
  worker_notes?: string;
}

export interface StatusTag {
  id: number;
  level: number;
  name: string;
}

export interface Worker {
  id?: number;
  type?: string;
  type_id?: number;
  name?: string;
  title?: string;
  image?: string;
  rating?: number;
  google_calendar_id?: number;
}

export interface RequestParams {
  [URL_PARAM_PAGE]: number;
  [URL_PARAM_PER_PAGE]?: number;
  [URL_PARAM_TYPED]?: string;
  [URL_PARAM_TERM]?: string;
  [URL_PARAM_SORT]?: string;
  [URL_PARAM_ORDER]?: string;
}

export interface PagedResults {
  count: number
  has_next: boolean,
  has_prev: boolean,
  items: object[],
  next_num: number,
  page: number,
  per_page: number,
  total: number
}
