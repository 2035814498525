import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contact, PagedResults } from '../../core/types';

export enum HomeCatalogStatus {
  Cataloged = 'completed',
  Scheduled = 'scheduled',
  NotScheduled = 'not_scheduled'
}

export interface Home {
  id: number;
  catalog_status: string;
  name: string;
  description: string;
  image: string;
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  state: string;
  created: string;
  market: Market;
  product_count: number;
  registered_product_count: number;
  subscriptions: Subscription[];
  type: string;
  type_id: number;
}

export interface HomeListItem  {
  address1: string;
  address2: string | null;
  appointment_count: number;
  appointment_finished: string | null;
  city: string;
  created: string;
  id: number;
  new_products: number | null;
  postal_code: string;
  registered: number | null;
  state: string;
  subscription_type: number;
  users: string[];
}

export interface Market {
  id: number;
  image: string;
  market_tier: number;
  name: string;
  timezone: string;
  type: string;
  type_id: number;
}

export interface Subscription {
  id: number;
  name: string;
}

export interface PagedHomeList extends PagedResults {
  items: Home[]
}

interface HomeState {
  pagedHomeList: PagedHomeList | null;
  contactList: Contact[] | null;
  selectedHome: Home | null;
  searchTerm: string;
  loading: boolean;
  error: string | null;
}

export const initialState: HomeState = {
  pagedHomeList: null,
  contactList: null,
  selectedHome: null,
  searchTerm: '',
  loading: false,
  error: null
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.pagedHomeList = null;
      state.loading = true;
    },
    searchHomeSuccess: (state, action: PayloadAction<PagedHomeList>) => {
      state.pagedHomeList = action.payload;
      state.loading = false;
    },
    searchHomeError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetSearch: (state) => {
      state.loading = false;
      state.pagedHomeList = null;
      state.error = '';
      state.contactList = null;
    },
    fetchHomeSuccess: (state, action: PayloadAction<any>) => {
      state.selectedHome = action.payload;
    },
    fetchHomeError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setSelectedHome: (state, action: PayloadAction<any>) => {
      state.selectedHome = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<any>) => {
      state.searchTerm = action.payload;
    },
    fetchHomeContactsSuccess: (state, action: PayloadAction<any>) => {
      const { items } = action.payload;
      state.contactList = items;
    },
    fetchHomeContactsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.contactList = null;
    }
  }
});

export const {
  fetchStart,
  searchHomeSuccess,
  searchHomeError,
  fetchHomeSuccess,
  fetchHomeError,
  setSelectedHome,
  setSearchTerm,
  resetSearch,
  fetchHomeContactsSuccess,
  fetchHomeContactsError
} = homeSlice.actions;

export default homeSlice.reducer;
