import { configureStore } from '@reduxjs/toolkit';
import { Action, combineReducers } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import appointmentDetailSlice from '../features/appointments/AppointmentDetailSlice';
import appointmentListSlice from '../features/appointments/AppointmentListSlice';
import assetListSlice from './components/asset-list/AssetListSlice';
import homeSlice from '../features/home/HomeSlice';
import loginSlice from '../features/login/LoginSlice';
import manufacturerSlice from '../features/manufacturers/ManufacturersSlice';
import productDetailSlice from '../features/products/ProductDetailSlice';
import productListSlice from '../features/products/ProductListSlice';
import productModelDetailSlice from '../features/models/ProductModelDetailSlice';
import productModelListSlice from '../features/models/ProductModelListSlice';

export const rootReducer = combineReducers({
  appointmentDetail: appointmentDetailSlice,
  appointmentList: appointmentListSlice,
  assetList: assetListSlice,
  home: homeSlice,
  login: loginSlice,
  manufacturers: manufacturerSlice,
  productDetail: productDetailSlice,
  productList: productListSlice,
  modelDetail: productModelDetailSlice,
  modelList: productModelListSlice,
});

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
