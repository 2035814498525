import {useState, useEffect} from "react";
import axios, { CancelTokenSource } from "axios";
import { PagedResults } from '../types';
import { constructApiUrl, getTokenHeader } from '../util/api';
import { SearchRouteParams } from '../util/form-helpers';

export interface AxoisFetchConfig {
  fieldName: string;
  apiRoute: string;
  apiParams: SearchRouteParams;
}

const useAxiosFetch = (config?: AxoisFetchConfig) => {
  const [ pagedResults, setPagedResults ] = useState<PagedResults|null>(null);
  const [ error, setError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ cancelTokenSource, setCancelTokenSource ] = useState<CancelTokenSource|null>(null);

  useEffect(() => {
    let unmounted = false;

    if (!!config) {
      if (!!cancelTokenSource) {
        cancelTokenSource?.cancel(`Operation canceled due to new search - ${ config.apiParams.term }`)
      }

      const params: string[] = [];
      if (!!config.apiParams) {
        Object.keys(config?.apiParams).forEach(key => params.push(`${ key }=${ config.apiParams[key] }`));
      }

      const url = constructApiUrl({ route: config.apiRoute + `?${ params.join('&') }` });
      axios.get(url, {
        cancelToken: cancelTokenSource?.token,
        headers: getTokenHeader()
      })
        .then(response => {
          if (!unmounted) {
            // @ts-ignore
            setPagedResults(response.data.results || request.data as PagedResults);
            setLoading(false);
            setCancelTokenSource(null)
          }
        }).catch(function (e) {
        console.log(e)
        if (!unmounted) {
          setPagedResults(null)
          setError(true);
          setErrorMessage(e.message);
          setLoading(false);
          if (axios.isCancel(e)) {
            console.log(`request cancelled:${ e.message }`);
          } else {
            console.log("another error happened:" + e.message);
          }
        }
      });
    }
    return function () {
      unmounted = true;
      cancelTokenSource?.cancel("Cancelling in cleanup");
    };
  }, [config, config?.apiParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return { pagedResults, loading, error, errorMessage };
};

export default useAxiosFetch;
