import * as React from 'react';
import { updateTask } from 'features/appointments/AppointmentDetailActions';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { HKAssetType, Task } from 'core/types';
import {
  formattedDateForTimezone,
  formatTimestampForGMT
} from '../../core/util/timezone';
import { copyJSON } from '../../core/util/helpers';
import {
  TASK_ASSET_DETAIL_URL,
  TASK_ASSET_LIST_URL
} from '../../core/util/constants';
import AssetList from '../../core/components/asset-list/AssetList';
import StatusTags from '../../core/components/status-tags/StatusTags';
import Notes from '../../core/components/notes/Notes';
import { updateTaskInState } from '../appointments/AppointmentDetailSlice';
import { CheckOutlined } from '@ant-design/icons';
import './Task.scss';

type AppointmentTaskProps = {
  task: Task;
  editable: boolean;
};

const AppointmentTask: React.FC<AppointmentTaskProps> = ({
  task,
  editable
}) => {
  const { selectedHome } = useSelector((state: RootState) => state.home);
  const { statusTags, updatingTaskId } = useSelector(
    (state: RootState) => state.appointmentDetail
  );
  const [levelClassName, setLevelClassName] = useState('');
  const dispatch = useDispatch();
  const closeNotesRef = useRef<() => void>();
  const closeAssetsRef = useRef<() => void>();

  const finishTask = () => {
    const taskCopy = copyJSON(task) as Task;
    taskCopy.finished = formatTimestampForGMT();
    dispatch(updateTask(taskCopy));
    closeChildRefs();
    updateLevelClassName();
  };

  const saveTask = () => {
    dispatch(updateTask(task));
    closeChildRefs();
  };

  const closeChildRefs = () => {
    if (!!closeNotesRef && !!closeNotesRef.current) {
      closeNotesRef.current();
    }
    if (!!closeAssetsRef && !!closeAssetsRef.current) {
      closeAssetsRef.current();
    }
  };

  const onTagToggle = (id: number) => {
    const taskCopy = copyJSON(task) as Task;
    if (taskCopy.tag_ids?.includes(id)) {
      taskCopy.tag_ids = taskCopy.tag_ids?.filter((tid) => tid !== id);
    } else {
      taskCopy.tag_ids?.push(id);
    }
    dispatch(updateTaskInState(taskCopy));
  };

  const onNotesChange = (notes: string) => {
    const taskCopy = copyJSON(task) as Task;
    taskCopy.worker_notes = notes;
    dispatch(updateTaskInState(taskCopy));
  };

  const updateLevelClassName = () => {
    const selectedTags = statusTags
      ?.filter((t) => task.tag_ids?.includes(t.id))
      .sort((a, b) => {
        return b.level - a.level;
      });
    const first = selectedTags?.shift();
    let level = '';
    if (!!first && !!task.finished) {
      level = `level-${first.level}`;
    } else if (!!task.finished) {
      level = 'level-0';
    }
    setLevelClassName(level);
  };

  useEffect(() => {
    if (!!task.finished) {
      updateLevelClassName();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateLevelClassName();
  }, [task]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`hk-task ${levelClassName}`}>
      <div className="hk-task-header">
        <h2>{task.name}</h2>
        <div className="hk-task-header-actions">
          {!task.finished ? (
            <Button
              className="hk-small-button"
              type="primary"
              disabled={!editable}
              loading={!!updatingTaskId && updatingTaskId === task.id}
              icon={<CheckOutlined />}
              onClick={finishTask}
            >
              Mark Completed
            </Button>
          ) : (
            <div className="hk-task-header-actions-finished">
              {formattedDateForTimezone(
                task.finished,
                selectedHome?.market.timezone
              )}
            </div>
          )}
          {!!task.finished && (
            <Button
              className="hk-small-button"
              type="default"
              disabled={!editable}
              loading={!!updatingTaskId && updatingTaskId === task.id}
              icon={<CheckOutlined />}
              onClick={saveTask}
            >
              Update Task
            </Button>
          )}
        </div>
      </div>

      <Notes
        title="Task Notes"
        notes={task.worker_notes || ''}
        editable={editable}
        onNotesUpdated={onNotesChange}
        closeNotesRef={closeNotesRef}
      />

      <StatusTags task={task} editable={editable} onToggleTag={onTagToggle} />
      <div className="hk-appointment-assets">
        <AssetList
          assetTypes={[HKAssetType.Photos, HKAssetType.Videos]}
          parent={task}
          editable={editable}
          preventDelete={true}
          urlConfig={{
            replaceParams: ['task_id', `${task.id}`],
            listURL: TASK_ASSET_LIST_URL,
            detailURL: TASK_ASSET_DETAIL_URL
          }}
          updateParentInStateAction={updateTaskInState}
          closeAssetsRef={closeAssetsRef}
        />
      </div>
    </div>
  );
};

export default AppointmentTask;
