import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store';
import { Breadcrumb, Button, Form, Spin } from 'antd';
import {
  Attribute,
  HKActionType,
  IObjectKeys,
  Manufacturer,
  PagedResults,
  ProductType,
  ValueType
} from '../../core/types';
import { resetProductModelList } from './ProductModelListSlice';
import {
  fetchProductModel,
  patchProductModel,
  postProductModel
} from './ProductModelDetailActions';
import {
  FormFieldControl,
  FormFieldCollection,
  SelectOption,
  buildFieldListForSchemaArray,
  stripDynamicFieldNamePrefix,
  AttributeField
} from '../../core/util/form-helpers';
import {
  MANUFACTURERS_LIST_URL,
  PRODUCT_MODEL_ASSET_DETAIL_URL,
  PRODUCT_MODEL_ASSET_LIST_URL,
  PRODUCT_TYPE_LIST_URL
} from '../../core/util/constants';
import {
  PagedManufacturerResults,
  resetManufacturerList,
  searchManufacturersSuccess
} from '../manufacturers/ManufacturersSlice';
import AdminForm, {
  defaultFormLayout,
  HKFormConfig
} from '../../core/components/admin-form/AdminForm';
import {
  PagedProductTypeResults,
  searchTypesSuccess,
  updateProductModel
} from './ProductModelDetailSlice';
import AssetList from '../../core/components/asset-list/AssetList';
import './ProductModelDetail.scss';
import { NavLink } from 'react-router-dom';

interface ModelParams {
  action: string;
  id?: string | undefined;
}

enum ProductSelect {
  Type = 'type',
  Manufacturer = 'manufacturer'
}

const ProductModelDetail: React.FC = () => {
  const { id, action } = useParams<ModelParams>();
  const { detailLoading, model, productTypeList, redirectTo, error } =
    useSelector((state: RootState) => state.modelDetail);

  const [formConfigured, setFormConfigured] = useState(false);
  const [attributeFields, setAttributeFields] = useState<
    AttributeField[] | null
  >(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const getFormConfig = (): HKFormConfig => {
    let config = formConfig;
    config.initialValues = getInitialFormValues();
    // set a single option for each of the fields which will be disabled on display
    if (!!model) {
      const productField = config.fields.find(
        (i) => i.name === ProductSelect.Type
      );
      if (!!productField) {
        productField.options = [
          { label: model.type_data.label || '', value: String(model.type) }
        ];
      }
      const manufacturerField = config.fields.find(
        (i) => i.name === ProductSelect.Manufacturer
      );
      if (!!manufacturerField) {
        manufacturerField.options = [
          {
            label: model.manufacturer?.name || '',
            value: String(model.manufacturer?.id)
          }
        ];
      }
    }
    return config;
  };

  const getInitialFormValues = () => {
    if (!!model) {
      const values: IObjectKeys = {
        name: model?.name,
        model: model?.model,
        description: model?.description,
        type: model?.type,
        manufacturer: String(model?.manufacturer.id)
      };
      return values;
    }
    return {};
  };

  const parseFormValuesToJSONBody = (values: any): object => {
    const { name, model, description, type, manufacturer } = values;
    const attributes: Attribute = {};
    if (!!attributeFields) {
      attributeFields.forEach((field) => {
        attributes[stripDynamicFieldNamePrefix(field.name)] =
          field?.type === ValueType.Number
            ? Number(values[field.name])
            : values[field.name];
      });
    }
    return {
      attributes: attributes,
      name: name,
      model: model,
      description: description,
      type: type,
      manufacturer_id: manufacturer
    };
  };

  const searchProductOptionsFormatter = (
    list: PagedResults
  ): SelectOption[] => {
    dispatch(searchTypesSuccess(list as PagedProductTypeResults));
    return (list as PagedProductTypeResults).items.map((type: ProductType) => {
      return { label: type.label, value: type.field };
    });
  };

  const searchManufacturerOptionsFormatter = (
    list: PagedResults
  ): SelectOption[] => {
    dispatch(searchManufacturersSuccess(list as PagedManufacturerResults));
    return (list as PagedManufacturerResults).items.map(
      (manufacturer: Manufacturer) => {
        return { label: manufacturer.name, value: String(manufacturer.id) };
      }
    );
  };

  const handleFieldChanged = (name: string, value: string | number) => {
    if (name === ProductSelect.Type) {
      const attributeSchema = productTypeList?.find(
        (t) => t.field === value
      )?.attribute_schema;
      setAttributeFields(buildFieldListForSchemaArray(attributeSchema));
    }
  };

  const onFinish = (values: any) => {
    const body = parseFormValuesToJSONBody(values);
    if (action === HKActionType.Add) {
      dispatch(postProductModel(body));
    }
    if (action === HKActionType.Edit && !!model) {
      dispatch(patchProductModel(model.id, body));
    }
  };

  useEffect(() => {
    if (action === HKActionType.Add) {
      setFormConfigured(true);
    } else if (action === HKActionType.Edit && !!model) {
      setAttributeFields(
        buildFieldListForSchemaArray(
          model.type_data.attribute_schema,
          model.attributes
        )
      );
      setFormConfigured(true);
    }
  }, [dispatch, model, action]);

  useEffect(() => {
    if (!!id && !model && action === HKActionType.Edit) {
      dispatch(fetchProductModel(id));
    }
  }, [dispatch, model, id, action]);

  useEffect(() => {
    if (!!redirectTo) {
      dispatch(resetProductModelList());
      history.push(redirectTo);
    }
  }, [dispatch, history, redirectTo]);

  useEffect(() => {
    dispatch(resetProductModelList());
    dispatch(resetManufacturerList());
    setAttributeFields(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formConfig: HKFormConfig = {
    action: action as HKActionType,
    initialValues: {},
    disabledFieldsForAction: {
      [HKActionType.Edit]: [ProductSelect.Type, ProductSelect.Manufacturer]
    },
    fields: [
      {
        name: ProductSelect.Type,
        label: 'Product Type',
        placeholder: 'Search product types',
        control: FormFieldControl.SelectSearch,
        collection: FormFieldCollection.Common,
        searchRoute: PRODUCT_TYPE_LIST_URL,
        searchResultsFormatter: searchProductOptionsFormatter,
        rules: [{ required: true, message: 'Missing product type' }]
      },
      {
        name: ProductSelect.Manufacturer,
        label: 'Manufacturer',
        placeholder: 'Search manufacturers',
        control: FormFieldControl.SelectSearch,
        collection: FormFieldCollection.Common,
        searchRoute: MANUFACTURERS_LIST_URL,
        searchResultsFormatter: searchManufacturerOptionsFormatter,
        options: [],
        rules: [{ required: true, message: 'Select a manufacturer' }]
      },
      {
        name: 'name',
        label: 'Name',
        control: FormFieldControl.Input,
        collection: FormFieldCollection.Common,
        rules: [{ required: true, message: 'Missing name' }]
      },
      {
        name: 'model',
        label: 'Model',
        control: FormFieldControl.Input,
        collection: FormFieldCollection.Common,
        rules: [{ required: true, message: 'Missing model' }]
      },
      {
        name: 'description',
        label: 'Description',
        control: FormFieldControl.Textarea,
        collection: FormFieldCollection.Common
      }
    ]
  };

  return (
    <div className="hk-model-detail">
      <Breadcrumb className="hk-products-breadcrumbs" separator=">">
        <Breadcrumb.Item>
          <NavLink to={'/models'}>Products</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Product</Breadcrumb.Item>
      </Breadcrumb>
      <h1>
        {!!action && action === HKActionType.Add ? 'Add' : 'Edit'} Product Model
      </h1>
      {(!formConfigured || detailLoading) && (
        <Spin className="hk-spinner" size="large" />
      )}
      {formConfigured && !detailLoading && (
        <AdminForm
          config={getFormConfig()}
          attributeFields={attributeFields}
          onFieldChanged={handleFieldChanged}
          onFinish={onFinish}
        >
          {!!model && !!model.assets && (
            <Form.Item label="Media">
              <AssetList
                parent={model}
                allowDefaultToggle={true}
                urlConfig={{
                  replaceParams: ['model_id', `${model.id}`],
                  listURL: PRODUCT_MODEL_ASSET_LIST_URL,
                  detailURL: PRODUCT_MODEL_ASSET_DETAIL_URL
                }}
                updateParentInStateAction={updateProductModel}
                showTitle={false}
              />
            </Form.Item>
          )}
          <Form.Item
            wrapperCol={{ ...defaultFormLayout.wrapperCol, offset: 6 }}
          >
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: '300px', margin: '24px 0' }}
            >
              Submit
            </Button>
          </Form.Item>
          {!!error && <div className="hk-login-error">{error}</div>}
        </AdminForm>
      )}
    </div>
  );
};

export default ProductModelDetail;
