import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagedResults, ProductModel } from '../../core/types';
import { copyJSON, removeItemFromArrayById } from '../../core/util/helpers';

export interface PagedProductModelResults extends PagedResults {
  items: ProductModel[]
}

interface ProductModelListState {
  listLoading: boolean;
  deleteModelId: number | null;
  searchTerm: string;
  error: string | null;
  pagedModelList: PagedProductModelResults | null
}

export const initialState: ProductModelListState = {
  listLoading: false,
  deleteModelId: null,
  searchTerm: '',
  error: null,
  pagedModelList: null,
};

export const productModelListSlice = createSlice({
  name: 'productModelList',
  initialState,
  reducers: {
    searchProductModelsStart: (state) => {
      state.listLoading = true;
    },
    searchProductModelsSuccess: (state, action: PayloadAction<PagedProductModelResults>) => {
      state.pagedModelList = action.payload;
      state.listLoading = false;
    },
    searchProductModelsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.listLoading = false;
    },
    resetProductModelList: (state) => {
      state.pagedModelList = null;
    },
    setDeleteModelId: (state, action: PayloadAction<number|null>) => {
      state.deleteModelId = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    deleteModelSuccess: (state) => {
      if (!!state.pagedModelList && state.deleteModelId) {
        state.pagedModelList.items = removeItemFromArrayById(copyJSON(state.pagedModelList.items) as ProductModel[], state.deleteModelId) as ProductModel[];
        state.deleteModelId = null;
      }
    },
    deleteModelError: (state) => {
      state.deleteModelId = null;
    },
  }
});

export const {
  searchProductModelsStart,
  searchProductModelsSuccess,
  searchProductModelsError,
  resetProductModelList,
  setDeleteModelId,
  setSearchTerm,
  deleteModelSuccess,
  deleteModelError
} = productModelListSlice.actions;

export default productModelListSlice.reducer;
