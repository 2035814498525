import { replaceParams, isDev, getBaseApiUrl } from './helpers';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import axios, { AxiosPromise } from 'axios';
import { AppThunk } from '../store';
import { logout } from '../../features/login/LoginSlice';
import {
  URL_PARAM_PAGE,
  URL_PARAM_PER_PAGE,
  DEFAULT_PER_PAGE,
  AUTH_TOKEN_KEY,
  URL_PARAM_TERM,
  URL_PARAM_TYPED,
  URL_PARAM_SORT,
  URL_PARAM_ORDER
} from './constants';
import store from '../store';
import { RequestParams } from '../types';

interface ApiUrlOptions {
  route: string;
  replace?: Array<any>;
}

export interface AsyncActionOptions {
  // Required Params:
  route: string;

  // Optional Params:
  method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
  replace?: Array<any>;
  body?: Object;
  params?: Object;
  defaultErrorMessage?: string;
  onStart?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onSuccess?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onError?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onComplete?: Function;
  useGlobalLoader?: boolean;
  // returnFullPayload?: boolean
}

export interface AsyncActionListOptions {
  promises: AxiosPromise[];
  defaultErrorMessage?: string;
  onStart?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onSuccess?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onError?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onComplete?: Function;
}

const getToken = () => {
  const { login } = store.getState()
  return login.accessToken;
}

export const getTokenHeader = () => {
  const token = getToken();
  return { "Authorization": token ? `Bearer ${token}` : null };
}

export const addOptionalURLParams = (url: string, params: RequestParams) => {
  const paramArray = [];
  if (!!params) {
    if (!!params.page) { paramArray.push(`${ URL_PARAM_PAGE }=${ params.page}`); }
    if (!!params.term) { paramArray.push(`${ URL_PARAM_TERM }=${ encodeURIComponent(params.term) }`); }
    if (!!params.typed) { paramArray.push(`${ URL_PARAM_TYPED }=${ encodeURIComponent(params.typed) }`); }
    if (!!params.sort) { paramArray.push(`${ URL_PARAM_SORT }=${ encodeURIComponent(params.sort) }`); }
    if (!!params.order) { paramArray.push(`${ URL_PARAM_ORDER }=${ encodeURIComponent(params.order) }`); }

    paramArray.push(`${URL_PARAM_PER_PAGE}=${ DEFAULT_PER_PAGE }`)
    if (paramArray.length) {
      return `${url}?${paramArray.join('&')}`
    }
  }
  return url;
}

export const constructApiUrl = (options: ApiUrlOptions): string => {
  const { route, replace } = options;
  const apiBaseUrl = getBaseApiUrl();

  if (isDev() && route[0] !== '/') {
    console.warn('Routes must start with \'/\'');
  }

  let url = apiBaseUrl;
  url += replace && replace.length ? replaceParams(route, replace) : route;

  return String(url);
};

export const parseAPIError = (error: any, defaultMessage: string|undefined): string|undefined => {
  let message = defaultMessage || ""
  if (!!error.response?.data?.error_description) {
    message = error.response.data.error_description
  }
  else if (!!error.response?.data?.error_data) {
    const data = error.response.data.error_data;
    Object.keys(data).forEach(key => {
      message += ` ${data[key].join(' ')}: ${key}.`
    })
  }
  else if (!!error.message) {
    message += error.message
  }
  return message;
}

export const createApiRequest = (options: AsyncActionOptions): Function => {
  const { route, replace, body, params, method, onStart, onSuccess, onComplete, onError, defaultErrorMessage } = options;
  return (): AppThunk => async (
    dispatch: any
  ) => {
    const url = constructApiUrl({ route, replace});
    if (typeof onStart === 'function') { dispatch(onStart(true)); }
    // if (useGlobalLoader) { dispatch(setLoading(true)); }
    if (Array.isArray(onStart) && typeof onStart[0] === 'function') {
      const params = onStart.slice(1);
      dispatch(onStart[0](...params));
    }
    try {
      const request = await axios({
        method: method || 'get',
        params: params,
        url,
        headers: getTokenHeader(),
        data: body
      });
      const results = request.data.results || request.data;
      if (typeof onSuccess === 'function') { dispatch(onSuccess(results)); }
      if (typeof onComplete === 'function') { dispatch(onComplete()); }
      // if (useGlobalLoader) { dispatch(setLoading(false)); }

    } catch (e: any) {
      const msg = parseAPIError(e, defaultErrorMessage);
      if (typeof onError === 'function') dispatch(onError(msg));
      if (!!e.response && (e.response.status === 401 || e.response.status === 422)) {
        localStorage.removeItem(AUTH_TOKEN_KEY);
        dispatch(logout());
      }
      if (isDev()) {
        console.error(`Homebook API Message:\n URL: ${url}\n Error: ${e.message}`);
      }
    }
  };
}

export const buildAxiosRequest = (options: AsyncActionOptions): AxiosPromise => {
  const { route, replace, body, params, method } = options;
  const url = constructApiUrl({ route, replace});
  return axios({
    method: method || 'get',
    params: params,
    url,
    headers: getTokenHeader(),
    data: body
  });
}

export const createApiBatchOfRequests = (options: AsyncActionListOptions): Function => {
  const { promises, onStart, onSuccess, onComplete, onError, defaultErrorMessage } = options;
  return (): AppThunk => async (
    dispatch: any
  ) => {
    if (typeof onStart === 'function') { dispatch(onStart(true)); }
    axios.all(promises).then(axios.spread((...responses) => {
      const results: object[] = [];
      responses.forEach(response => {
        const payload = response.data.results || response.data;
        results.push(payload)
      })
      if (typeof onSuccess === 'function') { dispatch(onSuccess(results)); }
      if (typeof onComplete === 'function') { dispatch(onComplete()); }
    })).catch(errors => {
      const msg = errors || defaultErrorMessage;
      if (typeof onError === 'function') dispatch(onError(msg));
    })
  }
}

export interface ExhaustionConfig {
  params?: {[index:string]:string|number|object[]};
  perPage?: number;
  useDefaultPagination?: boolean;
}

function get(options: AsyncActionOptions): AxiosPromise {
  const { route, replace, body, params, method } = options;
  const url = constructApiUrl({ route, replace});
  const token = getToken();
  return axios({
    method: method || 'get',
    params: params,
    url,
    headers: { "Authorization": token ? `Bearer ${token}` : null },
    data: body
  });
}

export const createApiRequestForAllPages = (options: AsyncActionOptions, config: ExhaustionConfig) : Function => {
  const { onStart, onSuccess } = options;
  return (): AppThunk => async (
    dispatch: any
  ) => {
    let NUMBER_PER_PAGE = 200;
    const newParams = { ...config.params };
    if (!config.useDefaultPagination) { NUMBER_PER_PAGE = config.perPage || 200; }

    let items: any = [];
    let currentPage = 1;

    if (typeof onStart === 'function') { dispatch(onStart(true)); }

    const fetchSinglePage = () => {
      if (!!options) {
        newParams[URL_PARAM_PAGE] = currentPage;
        if (NUMBER_PER_PAGE) { newParams[URL_PARAM_PER_PAGE] = NUMBER_PER_PAGE; }
        options.params = newParams;
        get(options).then(request => {
          const results = request.data.results || request.data;
          if (results && results.length) {
            items = items.concat(results);
          }
          if (request.data && request.data.has_next) {
            currentPage = request.data.next_num;
            fetchSinglePage();
          } else if (typeof onSuccess === 'function') {
            dispatch(onSuccess(items));
            return;
          }
        });
      }
    };

    fetchSinglePage();
  };
}
