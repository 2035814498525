import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagedResults, ProductModel, ProductType } from '../../core/types';

export interface PagedProductTypeResults extends PagedResults {
  items: ProductType[]
}

interface ProductModelDetailState {
  productTypeLoading: boolean;
  productTypeList: ProductType[] | null;
  productType: ProductType | null;
  model: ProductModel | null;
  selectedType: string | null;
  detailLoading: boolean;
  redirectTo: string | null;
  error: string | null;
}

export const initialState: ProductModelDetailState = {
  productTypeLoading: false,
  productType: null,
  productTypeList: null,
  model: null,
  selectedType: null,
  detailLoading: false,
  redirectTo: null,
  error: null,
};

export const productModelDetailSlice = createSlice({
  name: 'productModelDetail',
  initialState,
  reducers: {
    searchTypesSuccess: (state, action: PayloadAction<PagedProductTypeResults>) => {
      state.productTypeList = action.payload.items.sort(sortByLabel);
    },
    searchTypesError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fetchTypeListSuccess: (state, action: PayloadAction<PagedProductTypeResults>) => {
      state.productTypeList = action.payload.items.sort(sortByLabel);
    },
    fetchTypeListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fetchTypeDetailStart: (state, action: PayloadAction<any>) => {
      state.productTypeLoading = true;
    },
    fetchTypeDetailSuccess: (state, action: PayloadAction<any>) => {
      state.productType = action.payload;
      state.productTypeLoading = false;
    },
    fetchTypeDetailError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.productTypeLoading = false;
    },
    fetchModelDetailsStart: (state) => {
      state.detailLoading = true;
    },
    fetchModelDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.model = action.payload;
      state.detailLoading = false;
    },
    fetchModelDetailsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.model = null;
      state.detailLoading = false;
    },
    createProductModelStart: (state) => {
      state.detailLoading = true;
    },
    createProductModelSuccess: (state, action: PayloadAction<any>) => {
      state.model = action.payload;
      state.redirectTo = '/models';
      state.detailLoading = false;
    },
    createProductModelError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.detailLoading = false;
    },
    updateProductModel: (state, action: PayloadAction<any>) => {
      state.model = action.payload;
    },
    resetModelDetail: (state) => {
      state.model = null;
      state.redirectTo = null;
      state.detailLoading = false;
      state.productTypeList = null;
    },
    resetProductDetail: (state) => {
      state.productType = null;
      state.productTypeLoading = false;
      state.error = null;
    }
  }
});

export const {
  searchTypesSuccess,
  searchTypesError,
  fetchTypeListSuccess,
  fetchTypeListError,
  fetchTypeDetailStart,
  fetchTypeDetailSuccess,
  fetchTypeDetailError,
  resetProductDetail,
  fetchModelDetailsStart,
  fetchModelDetailsSuccess,
  fetchModelDetailsError,
  resetModelDetail,
  createProductModelStart,
  createProductModelSuccess,
  createProductModelError,
  updateProductModel
} = productModelDetailSlice.actions;

export default productModelDetailSlice.reducer;

function sortByLabel(a: ProductType, b: ProductType) {
  const aString = a.label.toLocaleLowerCase();
  const bString = b.label.toLocaleLowerCase();
  return aString === bString ? 0 : aString < bString ? -1 : 1
}
