import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Appointment, PagedResults } from '../../core/types';

export interface PagedAppointmentList extends PagedResults {
  items: Appointment[]
}

interface AppointmentsState {
  loading: boolean;
  error: string | null;
  pagedAppointmentList: PagedAppointmentList | null;
}

export const initialState: AppointmentsState = {
  loading: false,
  error: null,
  pagedAppointmentList: null,
};

export const appointmentListSlice = createSlice({
  name: 'appointmentList',
  initialState,
  reducers: {
    fetchAppointmentListStart: (state) => {
      state.pagedAppointmentList = null;
      state.loading = true;
    },
    fetchAppointmentListSuccess: (state, action: PayloadAction<PagedAppointmentList>) => {
      state.pagedAppointmentList = action.payload;
      state.loading = false;
    },
    fetchAppointmentListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetAppointmentList: (state) => {
      state.pagedAppointmentList = null;
      state.loading = false;
      state.error = null;
    },

  }
});

export const {
  fetchAppointmentListStart,
  fetchAppointmentListSuccess,
  fetchAppointmentListError,
  resetAppointmentList
} = appointmentListSlice.actions;

export default appointmentListSlice.reducer;
