import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logo from 'assets/illustrations/hk-logo-light.svg'
import { AUTH_TOKEN_KEY } from '../../util/constants';
import { logout } from '../../../features/login/LoginSlice';
import './SideMenu.scss'
import { useHistory } from 'react-router';

interface Page {
  title: string;
  path: string;
  icon?: string;
}

const pages: Page[] = [
  { title: 'Homes', path: '/homes'},
  { title: 'Products', path: '/models'},
];

const SideMenu: React.FC = () => {
  const { REACT_APP_VERSION } = process.env;
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutUser = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    dispatch(logout());
    history.push('/login');
  }

  return (
    <div className="hk-side-menu">
        <div className="hk-side-menu-links">
        <img src={Logo} className="hk-side-menu-links-logo" alt="HomeKeep Logo"/>
        {pages.map((page, i) => {
          return (
            <NavLink to={page.path} activeClassName="active" key={`nav-link-${i}`}>{page.title}</NavLink>
          );
        })}
      </div>
      <div className="hk-side-menu-footer">
        <h3 onClick={logoutUser}>Logout</h3>
        <div className="hk-side-menu-version">{REACT_APP_VERSION}</div>
      </div>
    </div>
  );
}

export default SideMenu;
