import { addOptionalURLParams, createApiRequest } from '../../core/util/api';
import {
  fetchHomeContactsError,
  fetchHomeContactsSuccess,
  fetchHomeError,
  fetchHomeSuccess,
  fetchStart,
  searchHomeError,
  searchHomeSuccess
} from './HomeSlice';
import { HOME_DETAILS_URL, HOME_CONTACTS_LIST_URL, HOME_LIST_SEARCH_URL } from '../../core/util/constants';
import { RequestParams } from '../../core/types';

export const searchHomes = (params: RequestParams) => createApiRequest({
  route: addOptionalURLParams(HOME_LIST_SEARCH_URL, params),
  // returnFullPayload: true,
  onStart: fetchStart,
  onSuccess: searchHomeSuccess,
  onError: searchHomeError,
  defaultErrorMessage: 'There was an error encountered while searching.'
})();

export const fetchHome = (homeId: number|string) => createApiRequest({
  route: HOME_DETAILS_URL,
  replace: ['home_id', homeId],
  onSuccess: fetchHomeSuccess,
  onError: fetchHomeError,
  defaultErrorMessage: 'There was an error fetching the home.'
})();

export const fetchHomeContacts = (homeId: number|string) => createApiRequest({
  route: HOME_CONTACTS_LIST_URL,
  replace: ['home_id', homeId],
  onSuccess: fetchHomeContactsSuccess,
  onError: fetchHomeContactsError,
  defaultErrorMessage: 'There was an error fetching the home.'
})();
