import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomeAppointments } from './AppointmentListActions';
import { RootState } from '../../core/store';
import { Breadcrumb, ConfigProvider, Table, TableProps } from 'antd';
import { customizeRenderEmpty, formatAddress } from '../../core/util/helpers';
import { Appointment } from '../../core/types';
import { fetchHome, fetchHomeContacts } from '../home/HomeActions';
import { ColumnProps } from 'antd/es/table';
import { formattedDateForTimezone } from '../../core/util/timezone';
import './AppointmentList.scss';
import { NavLink } from 'react-router-dom';

interface AppointmentListParams {
  homeid?: string | undefined;
}

const columns = (timezone: string): ColumnProps<any>[] => {
  return [
    {
      key: 'id',
      title: 'Name',
      render: (record: Appointment) => <div>{record.name}</div>
    },
    {
      key: 'completed_tasks',
      title: 'Completed Tasks',
      render: (record: Appointment) => <div>{record.completed_tasks}</div>
    },
    {
      key: 'scheduled',
      title: 'Scheduled',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: (record: Appointment) => (
        <div>{formattedDateForTimezone(record.scheduled, timezone)}</div>
      )
    },
    {
      key: 'completed',
      title: 'Completed',
      render: (record: Appointment) => (
        <div>
          {!!record.finished
            ? formattedDateForTimezone(record.finished, timezone)
            : '-'}
        </div>
      )
    }
  ];
};

const AppointmentList: React.FC = () => {
  const { homeid } = useParams<AppointmentListParams>();
  const { selectedHome, contactList } = useSelector(
    (state: RootState) => state.home
  );
  const { pagedAppointmentList, loading } = useSelector(
    (state: RootState) => state.appointmentList
  );
  const dispatch = useDispatch();
  const history = useHistory();
  let currentQueryParams = useQuery();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const updateQueryParams = (param: any) => {
    for (const [key, value] of Object.entries(param)) {
      const paramName: any = key;
      const paramValue: any = value;
      currentQueryParams.set(paramName, paramValue);
    }
    history.replace({
      search: new URLSearchParams(currentQueryParams).toString()
    });
  };

  const handleSortChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    const tableSort: any = { ...sorter };
    let columnName: string;
    switch (tableSort.column.title) {
      case 'Name':
        columnName = 'id';
        break;
      case 'Scheduled':
        columnName = 'scheduled';
        break;
      case 'Completed':
        columnName = 'completed';
        break;
      default:
        columnName = 'scheduled';
    }
    const sortParams = {
      page: '1',
      sort: columnName,
      order: tableSort.order === 'ascend' ? 'asc' : 'desc'
    };
    updateQueryParams(sortParams);
  };

  const handlePageChange = (page: number) => {
    fetchAppointments(page);
  };

  const selectAppointment = (appt: Appointment) => {
    history.push(`/home/${homeid}/appointments/${appt.id}`);
  };

  const fetchAppointments = (params: any) => {
    if (!!params) {
      params['page'] = Number(params.page);
    }
    if (!!homeid) {
      dispatch(fetchHomeAppointments(homeid, params));
    }
  };

  useEffect(() => {
    dispatch(fetchHome(Number(homeid)));
    dispatch(fetchHomeContacts(Number(homeid)));
    if (!!homeid && !pagedAppointmentList) {
      const defaultQueryParams = {
        sort: 'scheduled',
        order: 'asc',
        page: '1'
      };
      history.replace({
        search: new URLSearchParams(defaultQueryParams).toString()
      });
      fetchAppointments(defaultQueryParams);
    }
  }, []);

  useEffect(() => {
    const params: any = {
      sort: currentQueryParams.get('sort'),
      order: currentQueryParams.get('order'),
      page: currentQueryParams.get('page')
    };
    fetchAppointments(params);
  }, [currentQueryParams]);

  return (
    <div className="hk-appointments">
      <Breadcrumb className="hk-appointments-breadcrumbs" separator=">">
        <Breadcrumb.Item>
          <NavLink to={'/homes'}>Homes</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Appointments</Breadcrumb.Item>
      </Breadcrumb>

      <h1>Appointments</h1>
      <div className="hk-appointments-title">
        {selectedHome && <span>{formatAddress(selectedHome)}</span>}
        <p>
          <>
            {contactList?.map(
              (contact, index) =>
                `${contact.name}${index !== contactList.length - 1 ? ', ' : ' - '}`
            )}
          </>
          <>ID: {selectedHome?.id}</>
        </p>
      </div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          rowKey={(record) => record.id}
          rowClassName="hk-table-row"
          loading={{ spinning: loading, size: 'large' }}
          columns={columns(selectedHome?.market.timezone || '')}
          dataSource={pagedAppointmentList?.items || []}
          onChange={handleSortChange}
          pagination={{
            defaultCurrent: 1,
            total: pagedAppointmentList?.total,
            pageSize: pagedAppointmentList?.per_page,
            showSizeChanger: false,
            onChange: handlePageChange
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                selectAppointment(record);
              }
            };
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default AppointmentList;
