import * as React from 'react';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store';
import { ConfigProvider, Table, Input, Button, Image, Modal } from 'antd';
import { customizeRenderEmpty } from '../../core/util/helpers';
import {
  deleteProductModel,
  searchProductModels
} from './ProductModelListActions';
import { resetModelDetail } from './ProductModelDetailSlice';
import { Product, ProductModel } from '../../core/types';
import { setDeleteModelId, setSearchTerm } from './ProductModelListSlice';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { searchIconPrefix } from '../../core/util/form-helpers';
import './ProductModelList.scss';
import ImageIcon from '../../assets/icons/image.svg';
import { TABLE_ROW_THUMBNAIL_SIZE } from '../../core/util/constants';

const { Search } = Input;

const ProductModelList: React.FC = () => {
  const { pagedModelList, listLoading, deleteModelId, searchTerm } = useSelector(
    (state: RootState) => state.modelList
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const confirmDelete = (model: ProductModel) => {
    if (!!model) {
      Modal.confirm({
        className: 'hk-delete-confirm-modal',
        title: 'Remove Product Model',
        content: 'Are you sure you want to remove this product model?',
        closable: true,
        maskClosable: true,
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: () => {
          deleteModel(model);
        }
      });
    }
  };

  const columns = [
    {
      render: (record: Product) => (
        <div>
          {!!record.image ? (
            <Image
              className="hk-models-list-image"
              alt="Product"
              width={TABLE_ROW_THUMBNAIL_SIZE}
              height={TABLE_ROW_THUMBNAIL_SIZE}
              preview={false}
              src={record.image}
            />
          ) : (
            <img
              src={ImageIcon}
              width={TABLE_ROW_THUMBNAIL_SIZE}
              height={TABLE_ROW_THUMBNAIL_SIZE}
              className="hk-models-list-image-preview"
              alt="Model"
            />
          )}
        </div>
      )
    },
    {
      title: 'Name',
      render: (model: ProductModel) => <div>{model.name}</div>
    },
    {
      title: 'Type',
      render: (model: ProductModel) => (
        <div className="hk-models-list-type">{model.type}</div>
      )
    },
    {
      title: 'Model',
      render: (model: ProductModel) => (
        <div className="hk-models-list-model">{model.model}</div>
      )
    },
    {
      title: 'Actions',
      render: (model: ProductModel) => (
        <div>
          {deleteModelId && deleteModelId === model.id ? (
            <LoadingOutlined style={{ fontSize: 24, margin: '4px' }} spin />
          ) : (
            <div onClick={(e) => e.stopPropagation()}>
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => {
                  confirmDelete(model);
                }}
              />
            </div>
          )}
        </div>
      )
    }
  ];

  const fetchProductModels = (page: number, term?: string) => {
    dispatch(searchProductModels({ page: page, term: term }));
  };

  const handlePageChange = (page: number) => {
    fetchProductModels(page, searchTerm);
  };

  // This will insure a clean reset of the list
  // when returning to the view
  useEffect(() => {
    const defaultQueryParams: any = {
      page: '1'
    };
    if (searchTerm) {
      defaultQueryParams['term'] = searchTerm;
    }
    history.replace({
      search: new URLSearchParams(defaultQueryParams).toString()
    });
    handlePageChange(1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addModel = () => {
    dispatch(resetModelDetail());
    history.push(`/model/add`);
  };

  const selectModel = (model: ProductModel) => {
    dispatch(resetModelDetail());
    history.push(`/model/edit/${model.id}`);
  };

  const deleteModel = (model: ProductModel) => {
    dispatch(setDeleteModelId(model.id));
    dispatch(deleteProductModel(model.id));
  };

  const search = (term: string) => {
    fetchProductModels(1, term);
    dispatch(setSearchTerm(term));
  };

  return (
    <div className="hk-models">
      <h1>Product Models</h1>
      <div className="hk-models-header">
        <Search
          prefix={searchIconPrefix}
          placeholder="Enter model info"
          defaultValue={searchTerm}
          disabled={listLoading}
          enterButton="Search"
          size="large"
          allowClear
          onSearch={(e) => search(e)}
        />
        <Button
          className="hk-header-button"
          type="primary"
          onClick={addModel}
        >
          Add Product Model
        </Button>
      </div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          rowKey={(record) => record.id}
          rowClassName="hk-table-row"
          loading={{ spinning: listLoading, size: 'large' }}
          columns={columns}
          dataSource={pagedModelList?.items || []}
          pagination={{
            defaultCurrent: 1,
            total: pagedModelList?.total,
            pageSize: pagedModelList?.per_page,
            showSizeChanger: false,
            onChange: handlePageChange
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                selectModel(record);
              }
            };
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default ProductModelList;
