import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { copyJSON, removeItemFromArrayById } from '../../core/util/helpers';
import { PagedResults, Product } from '../../core/types';

export interface PagedProductList extends PagedResults {
  items: Product[]
}

interface ProductsState {
  loading: boolean;
  error: string | null;
  pagedProductList: PagedProductList | null;
  deleteProductId: number | null;
}

export const initialState: ProductsState = {
  loading: false,
  error: null,
  pagedProductList: null,
  deleteProductId: null,
};

export const productListSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    fetchProductListStart: (state) => {
      state.loading = true;
    },
    fetchProductListSuccess: (state, action: PayloadAction<PagedProductList>) => {
      state.pagedProductList = action.payload;
      state.loading = false;
    },
    fetchProductListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetProductList: (state) => {
      state.pagedProductList = null;
      state.loading = false;
      state.error = null;
      state.deleteProductId = null;
    },
    setDeleteProductId: (state, action: PayloadAction<number|null>) => {
      state.deleteProductId = action.payload;
    },
    deleteProductSuccess: (state) => {
      if (!!state.pagedProductList && state.deleteProductId) {
        state.pagedProductList.items = removeItemFromArrayById(
                                        copyJSON(state.pagedProductList.items) as Product[],
                                        state.deleteProductId
                                       ) as Product[];
        state.deleteProductId = null;
      }
    },
    deleteProductError: (state) => {
      state.deleteProductId = null;
    }
  }
});

export const {
  fetchProductListStart,
  fetchProductListSuccess,
  fetchProductListError,
  resetProductList,
  setDeleteProductId,
  deleteProductSuccess,
  deleteProductError
} = productListSlice.actions;

export default productListSlice.reducer;
