import * as React from 'react';
import { MutableRefObject, RefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { Button, Image, Input, Modal, Radio, Tooltip } from 'antd';
import {
  BookOutlined,
  CameraOutlined,
  CloseOutlined,
  FilePdfOutlined,
  HeartFilled,
  HeartOutlined,
  LoadingOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import ReactPlayer from 'react-player';
import { Asset, AssetListElement, HKAssetType } from '../../types';
import { copyJSON } from '../../util/helpers';
import {
  AssetActionStatus,
  AssetActionType,
  AssetListURLConfig,
  setAssetAction
} from './AssetListSlice';
import { addAsset, deleteAsset, updateAsset } from './AssetListActions';
import { RootState } from '../../store';
import { globeIconPrefix } from '../../util/form-helpers';
import './AssetList.scss';

const { Search } = Input;

type AssetListProps = {
  assetTypes?: HKAssetType[];
  parent: AssetListElement;
  urlConfig: AssetListURLConfig;
  allowDefaultToggle?: boolean;
  updateParentInStateAction: (a: AssetListElement | null) => Action;
  showTitle?: boolean;
  editable?: boolean;
  showCopy?: boolean;
  preventDelete?: boolean;
  closeAssetsRef?: MutableRefObject<any>;
  onCopyClick?: () => void;
};

const AssetList: React.FC<AssetListProps> = ({
  assetTypes = [HKAssetType.Photos, HKAssetType.Videos, HKAssetType.Manuals],
  parent,
  urlConfig,
  allowDefaultToggle = false,
  updateParentInStateAction,
  showTitle = true,
  editable = true,
  showCopy = false,
  preventDelete = false,
  closeAssetsRef,
  onCopyClick
}) => {
  const { assetAction, loading, error } = useSelector(
    (state: RootState) => state.assetList
  );
  const [editingAssets, setEditingAssets] = useState(false);
  const [assetType, setAssetType] = useState(HKAssetType.Photos);
  const [assetName, setAssetName] = useState<string | null>(null);
  const [urlPlaceholder, setUrlPlaceholder] = useState('Enter photo URL');
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const searchRef = React.useRef() as RefObject<any>;

  const dispatch = useDispatch();

  const closeAssets = () => {
    setEditingAssets(false);
  };

  const onAssetTypeChange = (e: any) => {
    if (!!e.target) {
      switch (e.target.value) {
        case HKAssetType.Photos:
          setAssetType(HKAssetType.Photos);
          setAssetName(null);
          setUrlPlaceholder('Enter photo URL');
          break;
        case HKAssetType.Videos:
          setAssetName(null);
          setAssetType(HKAssetType.Videos);
          setUrlPlaceholder('Enter video URL');
          break;
        case HKAssetType.Manuals:
          setAssetType(HKAssetType.Manuals);
          setUrlPlaceholder('Enter PDF manual URL');
          break;
      }
    }
  };

  const onAssetNameChange = (e: any) => {
    setAssetName(e.target.value);
  };

  const assetTypeLabel = (): string => {
    let label = assetType.substr(0, assetType.length - 1);
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  const add = (url?: string) => {
    if (!!parent && !!url) {
      dispatch(
        setAssetAction({
          parentId: parent.id,
          type: AssetActionType.Add,
          status: AssetActionStatus.Pending
        })
      );

      let asset: Asset = {
        file_url: url,
        type: assetType,
        default: !parent.assets || parent.assets.length === 0
      };
      if (asset.type === HKAssetType.Manuals && !!assetName) {
        asset.name = assetName;
      }
      dispatch(addAsset(urlConfig, asset));
      searchRef.current?.setValue('');
    }
  };

  const remove = (id: number | undefined) => {
    if (!!parent && !!id) {
      dispatch(
        setAssetAction({
          parentId: parent.id,
          id: id,
          type: AssetActionType.Delete,
          status: AssetActionStatus.Pending
        })
      );
      dispatch(deleteAsset(urlConfig, id));
    }
  };

  const confirmDelete = (id?: number) => {
    if (!!id) {
      Modal.confirm({
        className: 'hk-delete-confirm-modal',
        title: 'Remove Media Asset',
        content: 'Are you sure you want to remove this media asset?',
        closable: true,
        maskClosable: true,
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: () => {
          remove(id);
        }
      });
    }
  };

  const displayAssetThumbnail = (asset: Asset) => {
    return asset.thumb_cloud_store ? asset.thumb_cloud_store : asset.file_url;
  };

  const toggleParentImage = (asset: Asset) => {
    if (!!asset && !!asset.id) {
      const body = { ...asset, default: !asset.default };
      dispatch(updateAsset(urlConfig, body));
      const parentCopy = copyJSON(parent) as AssetListElement;
      parentCopy.assets
        .filter((a) => a.id !== asset.id)
        .map((a) => (a.default = false));
      const assetCopy = parentCopy.assets.find((a) => a.id === asset.id);
      if (!!assetCopy) {
        assetCopy.default = !assetCopy.default;
      }
      dispatch(updateParentInStateAction(parentCopy));
    }
  };

  const buttonForAssetType = (type: HKAssetType) => {
    switch (type) {
      case HKAssetType.Photos:
        return (
          <Radio.Button value={type} key={type}>
            <CameraOutlined /> Photo
          </Radio.Button>
        );
      case HKAssetType.Videos:
        return (
          <Radio.Button value={type} key={type}>
            <VideoCameraOutlined /> Video
          </Radio.Button>
        );
      case HKAssetType.Manuals:
        return (
          <Radio.Button value={type} key={type}>
            <BookOutlined /> Manual
          </Radio.Button>
        );
    }
  };

  useEffect(() => {
    if (!!assetAction && !!parent && assetAction.parentId === parent.id) {
      const parentCopy = copyJSON(parent) as AssetListElement;
      if (
        assetAction.status === AssetActionStatus.Success &&
        assetAction.type === AssetActionType.Add
      ) {
        let assets = copyJSON(parentCopy.assets) as Asset[];
        assets.push(assetAction.element as Asset);
        parentCopy.assets = assets;
      }
      if (
        assetAction.status === AssetActionStatus.Success &&
        assetAction.type === AssetActionType.Delete
      ) {
        const asset = parentCopy.assets.find((a) => a.id === assetAction.id);
        if (!!asset) {
          parentCopy.assets.splice(parentCopy.assets.indexOf(asset), 1);
        }
      }
      if (assetAction.status === AssetActionStatus.Success) {
        dispatch(updateParentInStateAction(parentCopy));
        dispatch(setAssetAction(null));
      }
    }
  }, [dispatch, parent, assetAction, assetAction?.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!closeAssetsRef) {
      closeAssetsRef.current = closeAssets;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="hk-asset-list">
      <div className="hk-asset-list-header">
        {showTitle && <h4>Media</h4>}
        <Button
          disabled={!editable}
          className="hk-action-button"
          onClick={() => setEditingAssets(!editingAssets)}
        >
          {editingAssets ? 'Close' : 'Edit'}
        </Button>
        {showCopy && (
          <Button
            disabled={!editable}
            type="primary"
            className="copy-product-button"
            onClick={() => onCopyClick ? onCopyClick() : null}
          >
            Copy & Add Product
          </Button>
        )}
      </div>
      <div className="hk-asset-list-items">
        {parent.assets.map((a) => {
          return (
            <div className={`hk-asset-list-items-item ${a.type}`} key={a.id}>
              {(a.type === HKAssetType.Photos || a.type === HKAssetType.Default) && (
                <Image
                  width={100}
                  height={100}
                  src={displayAssetThumbnail(a)}
                />
              )}
              {a.type === HKAssetType.Videos && (
                <span
                  className="hk-asset-list-items-item-media-icon"
                  onClick={() => setVideoURL(a.file_url)}
                >
                  <VideoCameraOutlined />
                </span>
              )}
              {a.type === HKAssetType.Manuals && (
                <Tooltip placement="topLeft" title={a.name}>
                  <a href={a.file_url} target="_blank" rel="noreferrer">
                    <span className="hk-asset-list-items-item-media-icon">
                      <FilePdfOutlined />
                    </span>
                  </a>
                </Tooltip>
              )}
              {editingAssets &&
                (!loading ||
                  !assetAction ||
                  (loading && assetAction && assetAction.id !== a.id)) && (
                  <div className="hk-asset-list-items-item-actions">
                    {allowDefaultToggle && a.type === HKAssetType.Photos && (
                      <Button
                        className="hk-asset-list-items-item-favorite"
                        disabled={loading}
                        shape="circle"
                        icon={a.default ? <HeartFilled /> : <HeartOutlined />}
                        onClick={() => toggleParentImage(a)}
                      />
                    )}
                    <Button
                      className="hk-asset-list-items-item-remove"
                      disabled={loading || preventDelete}
                      shape="circle"
                      icon={<CloseOutlined />}
                      onClick={() => {
                        confirmDelete(a.id);
                      }}
                    />
                  </div>
                )}
              {editingAssets &&
                !!assetAction &&
                assetAction.id === a.id &&
                loading && (
                  <div className="hk-asset-list-items-item-loading">
                    <LoadingOutlined style={{ fontSize: 24 }} spin={true} />
                  </div>
                )}
            </div>
          );
        })}
        {editingAssets &&
          !!assetAction &&
          assetAction.type === AssetActionType.Add &&
          assetAction.parentId === parent.id &&
          loading && (
            <div
              className="hk-asset-list-items-item placeholder"
              key={'loading'}
            >
              <LoadingOutlined style={{ fontSize: 20 }} spin={true} />
            </div>
          )}
      </div>
      {editingAssets && !!searchRef && (
        <div className="hk-asset-list-add">
          <div className="hk-asset-list-add-header">
            <Radio.Group
              className="hk-asset-list-add-header-type"
              buttonStyle="solid"
              defaultValue={assetType}
              size="large"
              onChange={onAssetTypeChange}
            >
              {assetTypes?.map((type) => {
                return buttonForAssetType(type);
              })}
            </Radio.Group>
            {assetType === HKAssetType.Manuals && (
              <span className="hk-asset-list-add-header-label">
                <label htmlFor="asset_name">Name:</label>
                <Input
                  size="large"
                  id="asset_name"
                  placeholder="Manual name"
                  onChange={(e) => onAssetNameChange(e)}
                />
              </span>
            )}
          </div>
          <Search
            ref={searchRef}
            prefix={globeIconPrefix}
            placeholder={urlPlaceholder}
            onSearch={add}
            enterButton={'Add ' + assetTypeLabel()}
            allowClear
          />
        </div>
      )}
      {editingAssets && error && (
        <div className="hk-asset-list-error">{error}</div>
      )}
      {!!videoURL && (
        <Modal
          wrapClassName="hk-video-modal"
          centered
          visible={!!videoURL}
          onCancel={() => setVideoURL(null)}
          width={'80%'}
          footer={null}
          bodyStyle={{
            padding: '0',
            margin: '0',
            backgroundColor: 'transparent'
          }}
        >
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={videoURL}
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    controls: true
                  }
                }
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AssetList;
