
export const LOCAL       = 'locl';
export const DEVELOPMENT = 'devl';
export const STAGING     = 'stag';
export const PRODUCTION  = 'prod';

// Numeric Values
export const BASE_REM_VAL = 16;
export const HALF_SECOND  = 500;
export const ONE_SECOND   = 1000;
export const TWO_SECONDS  = 2000;

export const GOOGLE_TRACKING_ID = 'G-2EDLEJPPY0';

// Database Type IDs
export const ASSET_TYPE_BATH    = 1;
export const ASSET_TYPE_CONTACT = 4;
export const ASSET_TYPE_PAINT   = 5;

// Styles
export const TABLE_ROW_THUMBNAIL_SIZE   = 40;


// Strings
export const HEX = 'Hex';



// API URLs & Constants
export const DEFAULT_PER_PAGE  = 10
export const SEARCH_DELAY = 300

export const URL_PARAM_PAGE = 'page';
export const URL_PARAM_PER_PAGE = 'per_page';
export const URL_PARAM_TERM = 'term';
export const URL_PARAM_TYPED = 'typed';
export const URL_PARAM_SORT = 'sort';
export const URL_PARAM_ORDER = 'order';


export const AUTH_URL                     = '/auth/password';
export const HOME_SEARCH_URL              = '/admin/homes';
export const HOME_LIST_SEARCH_URL         = '/admin/home_list';
export const HOME_DETAILS_URL             = '/admin/home/{home_id}';
export const HOME_CONTACTS_LIST_URL       = '/admin/home/{home_id}/contacts';

export const APPOINTMENT_LIST_URL               = '/admin/home/{home_id}/appointments'
export const APPOINTMENT_DETAIL_URL             = '/admin/appointment/{appt_id}';
export const APPOINTMENT_ASSET_LIST_URL         = '/admin/appointment/{appt_id}/assets';
export const APPOINTMENT_ASSET_DETAIL_URL       = '/admin/appointment/{appt_id}/asset/{asset_id}';
export const TASK_LIST_URL                      = '/admin/appointment/{appt_id}/appointment_tasks'
export const TASK_DETAIL_URL                    = '/admin/appointment_task/{task_id}'
export const TASK_ASSET_LIST_URL                = '/admin/appointment_task/{task_id}/assets';
export const TASK_ASSET_DETAIL_URL              = '/admin/appointment_task/{task_id}/asset/{asset_id}';
export const PRODUCT_MODEL_LIST_URL          = '/admin/product_templates';
export const PRODUCT_MODEL_DETAIL_URL        = '/admin/product_template/{model_id}';
export const PRODUCT_MODEL_ASSET_LIST_URL    = '/admin/product_template/{model_id}/assets';
export const PRODUCT_MODEL_ASSET_DETAIL_URL  = '/admin/product_template/{model_id}/asset/{asset_id}'
export const MANUFACTURERS_LIST_URL             = '/admin/manufacturers';
export const PRODUCT_TYPE_LIST_URL              = '/datatype/product_templates';
export const PRODUCT_TYPE_DETAIL_URL            = '/datatype/product_template/{attribute_id}';
export const PRODUCT_LIST_URL                   = '/admin/home/{home_id}/products';
export const PRODUCT_DETAIL_URL                 = '/admin/home/{home_id}/product/{product_id}';
export const PRODUCT_ASSET_LIST_URL             = '/admin/product/{prod_id}/assets';
export const PRODUCT_ASSET_DETAIL_URL           = '/admin/product/{prod_id}/asset/{asset_id}';
export const STATUS_TAG_LIST_URL                = '/data/status_tags';
export const USER_DETAILS_URL                   = '/admin/user/{user_id}';


// Date Formats
export const DATE_FORMAT_MONTH_DATE_HOURS_MINUTES   = 'MMM d, yyyy h:mma';
export const DATE_FORMAT_MONTH_DATE     = 'MMM d, yyyy';
export const DATE_FORMAT_WEEKDAY_DATE   = 'EEEE MMM d, yyyy';
export const DATE_FORMAT_HOUR_MINUTES   = 'h:mma';
export const DATE_FORMAT_TIMESTAMP = `yyyy-MM-dd'T'HH:mm:ssxxx`;
export const DATE_FORMAT_LONG_DATE = `P`;

// Regex
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PHONE_REGEX = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;

// Keys
export const AUTH_TOKEN_KEY = 'token';

export interface State {
  name: string;
  abbreviation: string;
}

export interface PackageConfiguration {
  type: string;
  name: string;
  features: string[];
}

export const STATE_LIST: State[] = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "District Of Columbia", abbreviation: "DC" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Puerto Rico", abbreviation: "PR" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" }
]

