import { createApiBatchOfRequests, createApiRequest, buildAxiosRequest } from '../../core/util/api';
import {
  APPOINTMENT_DETAIL_URL,
  STATUS_TAG_LIST_URL,
  TASK_DETAIL_URL,
  TASK_LIST_URL,
  USER_DETAILS_URL
} from '../../core/util/constants';
import {
  fetchAppointmentWorkerError,
  fetchAppointmentWorkerSuccess,
  fetchTaskListError,
  fetchTaskListStart,
  fetchTaskListSuccess,
  updateTaskStart,
  updateTaskSuccess,
  updateTaskError,
  updateAppointmentSuccess,
  updateAppointmentStart,
  updateAppointmentError,
  updateTaskListStart,
  updateTaskListSuccess,
  updateTaskListError
} from './AppointmentDetailSlice';
import {
  fetchAppointmentError,
  fetchAppointmentStart,
  fetchAppointmentSuccess,
  fetchStatusTagsError,
  fetchStatusTagsSuccess
} from './AppointmentDetailSlice';
import { Appointment, Task } from '../../core/types';

export const fetchAppointment = (id: string) => createApiRequest({
  route: APPOINTMENT_DETAIL_URL,
  replace: ['appt_id', id],
  onStart: fetchAppointmentStart,
  onSuccess: fetchAppointmentSuccess,
  onError: fetchAppointmentError,
  defaultErrorMessage: 'There was an error requesting this appointment.'
})();

export const updateAppointment = (appointment: Appointment) => createApiRequest({
  route: APPOINTMENT_DETAIL_URL,
  method: 'patch',
  replace: ['appt_id', appointment.id],
  body: appointment,
  onStart: updateAppointmentStart,
  onSuccess: updateAppointmentSuccess,
  onError: updateAppointmentError,
  defaultErrorMessage: 'There was an error requesting appointments for this home.'
})();

export const fetchStatusTags = () => createApiRequest({
  route: STATUS_TAG_LIST_URL,
  onSuccess: fetchStatusTagsSuccess,
  onError: fetchStatusTagsError,
  defaultErrorMessage: 'There was an error fetching the status tags.'
})();

export const fetchAppointmentTasks = (id: number) => createApiRequest({
  route: TASK_LIST_URL,
  replace: ['appt_id', id],
  onStart: fetchTaskListStart,
  onSuccess: fetchTaskListSuccess,
  onError: fetchTaskListError,
  defaultErrorMessage: 'There was an error requesting appointments for this home.'
})();

export const updateTask = (task: Task) => createApiRequest({
  route: TASK_DETAIL_URL,
  method: "patch",
  body: task,
  replace: ['task_id', task.id],
  onStart: [updateTaskStart, task.id],
  onSuccess: updateTaskSuccess,
  onError: updateTaskError,
  defaultErrorMessage: 'There was an error requesting tasks for this home.'
})();

export const updateAllTasks = (tasks: Task[]) => createApiBatchOfRequests({
  promises: tasks.map(task => buildAxiosRequest({
    route: TASK_DETAIL_URL,
    method: "patch",
    body: task,
    replace: ['task_id', task.id]
  })),
  onStart: updateTaskListStart,
  onSuccess: updateTaskListSuccess,
  onError: updateTaskListError,
  defaultErrorMessage: 'There was an error updating tasks for this appointment.'
})();

export const fetchAppointmentWorker = (id: number) => createApiRequest({
  route: USER_DETAILS_URL,
  replace: ['user_id', id],
  onSuccess: fetchAppointmentWorkerSuccess,
  onError: fetchAppointmentWorkerError,
  defaultErrorMessage: 'There was an error fetching the requested user.'
})();
