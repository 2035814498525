import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import AppointmentList from '../../features/appointments/AppointmentList';
import ProductList from '../../features/products/ProductList';
import AppointmentDetail from '../../features/appointments/AppointmentDetail';
import ProductModelList from '../../features/models/ProductModelList';
import ProductModelDetail from '../../features/models/ProductModelDetail';
import ProductDetail from '../../features/products/ProductDetail';
import HomeList from '../../features/home/HomeList';
import PrivateRoute from './PrivateRoute';
import Login from '../../features/login/Login';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login"/>
      </Route>
      <Route path="/login">
       <Login/>
      </Route>
      <PrivateRoute path="/homes">
        <HomeList/>
      </PrivateRoute>
      <PrivateRoute exact path="/home/:homeid/products">
        <ProductList/>
      </PrivateRoute>
      <PrivateRoute exact path="/home/:homeid/product/:action/:id?">
        <ProductDetail/>
      </PrivateRoute>
      <PrivateRoute exact path="/home/:homeid/appointments">
        <AppointmentList/>
      </PrivateRoute>
      <PrivateRoute exact path="/home/:homeid/appointments/:id">
        <AppointmentDetail/>
      </PrivateRoute>
      <PrivateRoute exact path="/models">
        <ProductModelList/>
      </PrivateRoute>
      <PrivateRoute exact path="/model/:action/:id?">
        <ProductModelDetail/>
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
