import { createApiRequest } from '../../core/util/api';
import { AUTH_URL } from '../../core/util/constants';
import { loginError, loginStart, loginSuccess } from './LoginSlice';

export const loginUser = (body: object) => createApiRequest({
  route: AUTH_URL,
  method: 'post',
  body: body,
  onStart: loginStart,
  onSuccess: loginSuccess,
  onError: loginError,
  defaultErrorMessage: 'There was an error authenticating your account.'
})();