import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTH_TOKEN_KEY } from '../../core/util/constants';

interface LoginState {
  email: string | null;
  accessToken: string | null;
  loggedIn: boolean;
  loading: boolean;
  error: string | null;
}

export const initialState: LoginState = {
  email: null,
  accessToken: !!localStorage.getItem(AUTH_TOKEN_KEY) ? String(localStorage.getItem(AUTH_TOKEN_KEY)) : null,
  loggedIn: !!localStorage.getItem(AUTH_TOKEN_KEY),
  loading: false,
  error: null
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginStart: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
      state.loading = true;
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      const { access_token } = action.payload;
      state.accessToken = access_token;
      state.loggedIn = true;
      state.loading = false;
    },
    loginError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setLoggedInWithLocalStorage: (state, action: PayloadAction<any>) => {
      state.accessToken = action.payload;
      state.loggedIn = true;
    },
    logout: (state) => {
      state.email = null;
      state.accessToken = null;
      state.loggedIn = false;
    }
  }
});

export const {
  loginStart,
  loginSuccess,
  loginError,
  setLoggedInWithLocalStorage,
  logout
} = loginSlice.actions;

export default loginSlice.reducer;
