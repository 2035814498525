import { createSlice } from '@reduxjs/toolkit';

export enum AssetActionType {
  Add = 'add',
  Update = 'update',
  Delete = 'delete'
}

export interface AssetListURLConfig {
  replaceParams: string[];
  listURL: string;
  detailURL: string;
}

export enum AssetActionStatus {
  Pending,
  Success ,
  Failure
}

export interface AssetAction {
  parentId: number;
  id?: number;
  element?: object;
  type: AssetActionType;
  status: AssetActionStatus;
}

interface AssetListState {
  loading: boolean;
  error: string | null;
  assetAction: AssetAction | null;
}

export const initialState: AssetListState = {
  loading: false,
  error: null,
  assetAction: null
};

export const assetListSlice = createSlice({
  name: 'assetList',
  initialState,
  reducers: {
    startAssetAction: (state) => {
      state.error = null;
      state.loading = true;
    },
    setAssetAction: (state, action) => {
      state.assetAction = action.payload;
    },
    addAssetSuccess: (state, action) => {
      state.loading = false;
      if (!!state.assetAction) {
        state.assetAction.element = action.payload;
        state.assetAction.status = AssetActionStatus.Success;
      }
    },
    addAssetError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      if (!!state.assetAction) {
        state.assetAction.status = AssetActionStatus.Failure;
      }
    },
    deleteAssetSuccess: (state) => {
      state.loading = false;
      if (!!state.assetAction) {
        state.assetAction.status = AssetActionStatus.Success;
      }
    },
    deleteAssetError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      if (!!state.assetAction) {
        state.assetAction.status = AssetActionStatus.Failure;
      }
    },
    updateAssetSuccess: (state, action) => {
      state.loading = false;
    },
    updateAssetFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const {
  startAssetAction,
  setAssetAction,
  addAssetSuccess,
  addAssetError,
  deleteAssetSuccess,
  deleteAssetError,
  updateAssetSuccess,
  updateAssetFailure
} = assetListSlice.actions;

export default assetListSlice.reducer;

