import { addOptionalURLParams, createApiRequest } from '../../core/util/api';
import { PRODUCT_DETAIL_URL, PRODUCT_LIST_URL } from '../../core/util/constants';
import {
  deleteProductError,
  deleteProductSuccess,
  fetchProductListError,
  fetchProductListStart,
  fetchProductListSuccess,
} from './ProductListSlice';
import { RequestParams } from '../../core/types';

export const fetchHomeProductList = (id: string, params: RequestParams) => createApiRequest({
  route: addOptionalURLParams(PRODUCT_LIST_URL, params),
  replace: ['home_id', id],
  onStart: fetchProductListStart,
  onSuccess: fetchProductListSuccess,
  onError: fetchProductListError,
  defaultErrorMessage: 'There was an error requesting products for this home.'
})();

export const deleteHomeProduct = (homeid: string, id: number) => createApiRequest({
  route: PRODUCT_DETAIL_URL,
  replace: [
    ['home_id', homeid],
    ['product_id', id]
  ],
  method: 'delete',
  onSuccess: deleteProductSuccess,
  onError: deleteProductError,
  defaultErrorMessage: 'There was an error deleting the product models.'
})();
