import { addOptionalURLParams, createApiRequest } from '../../core/util/api';
import {
  PRODUCT_MODEL_DETAIL_URL,
  PRODUCT_MODEL_LIST_URL,
  PRODUCT_TYPE_DETAIL_URL,
  PRODUCT_TYPE_LIST_URL
} from '../../core/util/constants';
import {
  createProductModelError,
  createProductModelStart,
  createProductModelSuccess,
  fetchModelDetailsError,
  fetchModelDetailsStart,
  fetchModelDetailsSuccess, fetchTypeDetailError, fetchTypeDetailStart, fetchTypeDetailSuccess,
  fetchTypeListError,
  fetchTypeListSuccess, searchTypesError, searchTypesSuccess
} from './ProductModelDetailSlice';
import { RequestParams } from '../../core/types';


export const fetchProductModel = (id: string) => createApiRequest({
  route: PRODUCT_MODEL_DETAIL_URL,
  replace: ['model_id', id],
  onStart: fetchModelDetailsStart,
  onSuccess: fetchModelDetailsSuccess,
  onError: fetchModelDetailsError,
  defaultErrorMessage: 'There was an error fetching the product models.'
})();

export const postProductModel = (body: object) => createApiRequest({
  route: PRODUCT_MODEL_LIST_URL,
  method: 'post',
  body: body,
  onStart: createProductModelStart,
  onSuccess: createProductModelSuccess,
  onError: createProductModelError,
  defaultErrorMessage: 'There was an error updatings the product models.'
})();

export const patchProductModel = (id: number, body: object) => createApiRequest({
  route: PRODUCT_MODEL_DETAIL_URL,
  replace: ['model_id', id],
  method: 'patch',
  body: body,
  onStart: createProductModelStart,
  onSuccess: createProductModelSuccess,
  onError: createProductModelError,
  defaultErrorMessage: 'There was an error updating the product models.'
})();

export const fetchProductTypes = () => createApiRequest({
  route: PRODUCT_TYPE_LIST_URL,
  onSuccess: fetchTypeListSuccess,
  onError: fetchTypeListError,
  defaultErrorMessage: 'There was an error fetching the product types.'
})();

export const fetchProductTypeDetail = (id: string) => createApiRequest({
  route: PRODUCT_TYPE_DETAIL_URL,
  replace: ['attribute_id', id],
  onStart: fetchTypeDetailStart,
  onSuccess: fetchTypeDetailSuccess,
  onError: fetchTypeDetailError,
  defaultErrorMessage: 'There was an error fetching the product types.'
})();

export const searchProductTypes = (params: RequestParams) => createApiRequest({
  route: addOptionalURLParams(PRODUCT_TYPE_LIST_URL, params),
  onSuccess: searchTypesSuccess,
  onError: searchTypesError,
  defaultErrorMessage: 'There was an error fetching the product types.'
})();
