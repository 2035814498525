import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import store from './core/store';
import Layout from './core/components/layout/Layout';
import Routes from './core/routes/routes';
import './App.scss';

function App() {
  return (
    <Router>
      <Provider store={store}>
        <Layout>
          <Routes/>
        </Layout>
      </Provider>
    </Router>
  );
}

export default App;
