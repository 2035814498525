import * as React from 'react';
import {
  Button,
  ConfigProvider,
  Input,
  Table,
  TableProps,
  Tooltip
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { DatabaseOutlined, ScheduleOutlined } from '@ant-design/icons';
import { RootState } from '../../core/store';
import { customizeRenderEmpty, formatAddress } from '../../core/util/helpers';
import { HomeListItem, setSearchTerm } from './HomeSlice';
import { resetAppointmentList } from '../appointments/AppointmentListSlice';
import { fetchHome, fetchHomeContacts, searchHomes } from './HomeActions';
import { resetProductList } from '../products/ProductListSlice';
import { searchIconPrefix } from '../../core/util/form-helpers';
import './HomeList.scss';
import { useEffect } from 'react';
import { DATE_FORMAT_LONG_DATE } from 'core/util/constants';
import { format, parseISO } from 'date-fns';
import { ColumnsType } from 'antd/lib/table';

enum SubscriptionType {
  EssentialPlan = 1,
  PreferredPlan = 2,
  DigitalPlan = 34
}
const { Search } = Input;

const HomeList: React.FC = () => {
  const { pagedHomeList, searchTerm, loading } = useSelector(
    (state: RootState) => state.home
  );

  let currentQueryParams = useQuery();

  const dispatch = useDispatch();
  const history = useHistory();

  const fetchHomeListSearch = (params: any) => {
    if (!!params) {
      params['page'] = Number(params.page);
    }
    dispatch(searchHomes(params));
  };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const updateQueryParams = (param: any) => {
    for (const [key, value] of Object.entries(param)) {
      const paramName: any = key;
      const paramValue: any = value;
      currentQueryParams.set(paramName, paramValue);
    }
    history.replace({
      search: new URLSearchParams(currentQueryParams).toString()
    });
  };

  const search = (term: string) => {
    updateQueryParams({ term: term.toString() });
    dispatch(setSearchTerm(term.toString()));
  };

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    const tableExtraData: any = { ...extra };
    const tablePagination: any = { ...pagination };
    const tableSort: any = { ...sorter };
    let columnName: string;
    switch (tableSort.column.title) {
      case 'Status':
        columnName = 'new_products';
        break;
      case 'Address':
        columnName = 'address1';
        break;
      case 'Sign Up Date':
        columnName = 'created';
        break;
      case 'HomeID':
        columnName = 'id';
        break;
      case 'Reg. Products':
        columnName = 'registered';
        break;
      default:
        columnName = 'new_products';
    }
    const sortParams = {
      page:
        tableExtraData.action === 'paginate' ? tablePagination.current : '1',
      sort: columnName,
      order: tableSort.order === 'ascend' ? 'asc' : 'desc'
    };
    updateQueryParams(sortParams);
  };

  const viewAppointments = (home: HomeListItem) => {
    dispatch(resetAppointmentList());
    history.push(`/home/${home.id}/appointments`);
  };

  const viewProducts = (home: HomeListItem) => {
    dispatch(resetProductList());
    history.push(`/home/${home.id}/products`);
  };

  // Fetch home list each time query params are updated
  useEffect(() => {
    const params: any = {
      sort: currentQueryParams.get('sort'),
      order: currentQueryParams.get('order'),
      page: currentQueryParams.get('page')
    };
    if (currentQueryParams.get('term')) {
      params['term'] = currentQueryParams.get('term');
    }
    fetchHomeListSearch(params);
  }, [currentQueryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  // This will insure a clean reset of the list
  // when returning to the view
  useEffect(() => {
    const defaultQueryParams: any = {
      sort: 'new_products',
      order: 'desc',
      page: '1'
    };
    if (searchTerm) {
      defaultQueryParams['term'] = searchTerm;
    }
    history.replace({
      search: new URLSearchParams(defaultQueryParams).toString()
    });
    fetchHomeListSearch(defaultQueryParams);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const iconForStatus = (home: HomeListItem) => {
    if (home.new_products !== null) {
      return (
        <>
          <div className="status-circle new-product" />
          New Product
        </>
      );
    }
    if (home.appointment_finished === null) {
      return (
        <>
          <div className="status-circle new-home" />
          New Home
        </>
      );
    }
    if (home.appointment_finished !== null) {
      return (
        <>
          <div className="status-circle completed" />
          Completed
        </>
      );
    }
    return <></>;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Status',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (record: HomeListItem) => (
        <span className="hk-catalog-status">{iconForStatus(record)}</span>
      )
    },
    {
      title: 'Homeowner',
      render: (record: HomeListItem) => {
        const areMultipleUsers: boolean =
          !!record.users && record.users.length > 1;
        let tooltipUsers: string[] = [];
        if (areMultipleUsers) {
          tooltipUsers = record.users.slice(1);
        }
        return (
          <span className="hk-catalog-homeowner">
            {!record.users && '-'}
            {!!record.users && record.users.length === 1 && (
              <>{record.users[0]}</>
            )}
            {areMultipleUsers && (
              <>
                {record.users[0]}
                <Tooltip title={tooltipUsers.join(', ')}>
                  <span className="hk-catalog-homeowner-list">
                    {' '}
                    +{record.users.length - 1} more
                  </span>
                </Tooltip>
              </>
            )}
          </span>
        );
      }
    },
    {
      title: 'Address',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: HomeListItem) => <div>{formatAddress(record)}</div>
    },
    {
      title: 'Sign Up Date',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: HomeListItem) => (
        <span className="hk-catalog-sign-up-date">
          {format(parseISO(record.created), DATE_FORMAT_LONG_DATE)}
        </span>
      )
    },
    {
      title: 'Reg. Products',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: HomeListItem) => (
        <span className="hk-catalog-home-id">
          {record.registered ? record.registered : 0}
        </span>
      )
    },
    {
      title: 'HomeID',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: HomeListItem) => (
        <span className="hk-catalog-home-id">{record.id}</span>
      )
    },
    {
      title: 'Actions',
      render: (record: HomeListItem) => (
        <div className="hk-catalog-actions">
          <Tooltip
            title={
              record.subscription_type !== SubscriptionType.DigitalPlan
                ? 'Appointments '
                : 'Apppointments Disabled'
            }
          >
            <Button
              className={`hk-small-button ${
                record.subscription_type === SubscriptionType.DigitalPlan &&
                'disabled'
              }`}
              onClick={(e) => {
                e.stopPropagation();
                viewAppointments(record);
              }}
            >
              <ScheduleOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Products">
            <Button
              className="hk-small-button"
              onClick={(e) => {
                e.stopPropagation();
                viewProducts(record);
              }}
            >
              <DatabaseOutlined />
            </Button>
          </Tooltip>
        </div>
      )
    }
  ];

  return (
    <div className="hk-home">
      <h1>Search homes to get started.</h1>
      <Search
        prefix={searchIconPrefix}
        placeholder="Enter a home address or homeowner"
        defaultValue={searchTerm}
        disabled={loading}
        enterButton="Search"
        size="large"
        allowClear
        onSearch={(e) => search(e)}
      />
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          rowKey={(record) => record.id}
          rowClassName="hk-table-row"
          loading={{ spinning: loading, size: 'large' }}
          columns={columns}
          dataSource={pagedHomeList?.items || []}
          onChange={handleTableChange}
          pagination={{
            defaultCurrent: 1,
            total: pagedHomeList?.total,
            pageSize: pagedHomeList?.per_page,
            showSizeChanger: false
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default HomeList;
