import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '../../core/types';

interface ProductsState {
  loading: boolean;
  modelsLoaded: boolean;
  error: string | null;
  product: Product | null;
  redirectToPattern: string | null;
}

export const initialState: ProductsState = {
  loading: false,
  modelsLoaded: false,
  error: null,
  product: null,
  redirectToPattern: null
};

export const productDetailSlice = createSlice({
  name: 'productDetail',
  initialState,
  reducers: {
    fetchProductStart: (state) => {
      state.loading = true;
    },
    fetchProductSuccess: (state, action: PayloadAction<any>) => {
      state.product = action.payload;
      state.loading = false;
    },
    fetchProductError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.product = null;
      state.loading = false;
    },
    createProductStart: (state) => {
      state.loading = true;
    },
    createProductSuccess: (state, action: PayloadAction<any>) => {
      state.product = action.payload;
      state.redirectToPattern = `/home/{home_id}/products`;
      state.loading = false;
    },
    createProductError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateProduct: (state, action: PayloadAction<any>) => {
      state.product = action.payload;
    },
    removeProduct: (state) => {
      state.product = null;
      state.redirectToPattern = null;
    }
  }
});

export const {
  fetchProductStart,
  fetchProductSuccess,
  fetchProductError,
  createProductStart,
  createProductSuccess,
  createProductError,
  updateProduct,
  removeProduct
} = productDetailSlice.actions;

export default productDetailSlice.reducer;
