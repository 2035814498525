import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Breadcrumb,
  Button,
  ConfigProvider,
  Image,
  Modal,
  Table,
  Tooltip
} from 'antd';
import { RootState } from '../../core/store';
import { deleteHomeProduct, fetchHomeProductList } from './ProductListActions';
import { customizeRenderEmpty, formatAddress } from '../../core/util/helpers';
import { setDeleteProductId } from './ProductListSlice';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import { removeProduct } from './ProductDetailSlice';
import { formatTimestampForTimezone } from '../../core/util/timezone';
import {
  DATE_FORMAT_LONG_DATE,
  TABLE_ROW_THUMBNAIL_SIZE
} from '../../core/util/constants';
import ImageIcon from '../../assets/icons/image.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as FlagIcon } from '../../assets/icons/flag.svg';
import { fetchHome, fetchHomeContacts } from '../home/HomeActions';
import { Product } from '../../core/types';
import './ProductList.scss';
import { NavLink } from 'react-router-dom';

interface ProductListParams {
  homeid?: string | undefined;
}

const ProductList: React.FC = () => {
  const { homeid } = useParams<ProductListParams>();
  const { selectedHome, contactList } = useSelector(
    (state: RootState) => state.home
  );
  const { pagedProductList, deleteProductId, loading } = useSelector(
    (state: RootState) => state.productList
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const confirmDelete = (product: Product) => {
    if (!!product) {
      Modal.confirm({
        className: 'hk-delete-confirm-modal',
        title: 'Remove Product',
        content: 'Are you sure you want to remove this product?',
        closable: true,
        maskClosable: true,
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: () => {
          deleteProduct(product);
        }
      });
    }
  };

  const iconForStatus = (product: Product) => {
    if (product.is_generic === true) {
      return (
        <>
          <div className="status-circle new-product" />
          New Product
        </>
      );
    } else {
      return (
        <>
          <div className="status-circle completed" />
          Completed
        </>
      );
    }
  };

  const columns = [
    {
      key: 'id',
      width: '5%',
      render: (record: Product) => (
        <div onClick={() => selectProduct(record)}>
          {!!record.image ? (
            <Image
              className="hk-products-list-image"
              src={record.image}
              width={TABLE_ROW_THUMBNAIL_SIZE}
              height={TABLE_ROW_THUMBNAIL_SIZE}
              preview={false}
              alt="Product"
            />
          ) : (
            <img
              className="hk-products-list-image-preview"
              src={ImageIcon}
              alt="Product placeholder"
              width={TABLE_ROW_THUMBNAIL_SIZE}
              height={TABLE_ROW_THUMBNAIL_SIZE}
            />
          )}
        </div>
      )
    },
    {
      title: 'Status',
      width: 10,
      render: (record: Product) => (
        <span className="hk-products-status">{iconForStatus(record)}</span>
      )
    },
    {
      key: 'name',
      title: 'Name',
      render: (record: Product) => (
        <div>{!!record.name ? record.name : record.template_name}</div>
      )
    },
    {
      key: 'registered',
      title: 'Registered',
      render: (record: Product) => (
        <div>
          {!!selectedHome && record.registered
            ? formatTimestampForTimezone(
                selectedHome.market.timezone,
                record.registered,
                DATE_FORMAT_LONG_DATE
              )
            : 'N/A'}
        </div> // selectedHome.market.timezone
      )
    },
    {
      key: 'id',
      title: 'Actions',
      render: (product: Product) => (
        <div className="hk-products-actions">
          <Tooltip title="Delete">
            {deleteProductId && deleteProductId === product.id ? (
              <LoadingOutlined style={{ fontSize: 24, margin: '4px' }} spin />
            ) : (
              <div onClick={(e) => e.stopPropagation()}>
                <Button
                  className="hk-small-button"
                  onClick={() => {
                    confirmDelete(product);
                  }}
                >
                  <Icon style={{ fontSize: 16 }} component={TrashIcon} />
                </Button>
              </div>
            )}
          </Tooltip>
          <Button className="hk-small-button disabled">
            <Icon style={{ fontSize: 16 }} component={FlagIcon} />
          </Button>
        </div>
      )
    }
  ];

  const fetchHomeProducts = (page: number) => {
    if (!!homeid) {
      dispatch(
        fetchHomeProductList(homeid, {
          sort: 'created',
          order: 'desc',
          page: page
        })
      );
    }
  };

  const handlePageChange = (page: number) => {
    fetchHomeProducts(page);
  };

  const addProduct = () => {
    dispatch(removeProduct());
    history.push(`/home/${homeid}/product/add`);
  };

  const selectProduct = (product: Product) => {
    dispatch(removeProduct());
    history.push(`/home/${homeid}/product/edit/${product.id}`);
  };

  const deleteProduct = (product: Product) => {
    if (homeid) {
      dispatch(setDeleteProductId(product.id));
      dispatch(deleteHomeProduct(homeid, product.id));
    }
  };

  useEffect(() => {
    if (!!homeid) {
      dispatch(fetchHome(homeid));
      dispatch(fetchHomeContacts(homeid));
    }
  }, []);

  useEffect(() => {
    if (!!homeid && !pagedProductList) {
      fetchHomeProducts(1);
    }
  }, []);

  return (
    <div className="hk-products">
      <Breadcrumb className="hk-products-breadcrumbs" separator=">">
        <Breadcrumb.Item>
          <NavLink to={'/homes'}>Homes</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Home Products</Breadcrumb.Item>
      </Breadcrumb>
      <div className="hk-products-total">Registered Total: {selectedHome?.registered_product_count}</div>
      <div className="hk-products-header">
        <div className="hk-products-header-title">
          <h1>Home Products</h1>
          {selectedHome && <span>{formatAddress(selectedHome)}</span>}
          <p>
            <>
              {contactList?.map(
                (contact, index) =>
                  `${contact.name}${index !== contactList.length - 1 ? ', ' : ' - '}`
              )}
            </>
            <>ID: {selectedHome?.id}</>
          </p>
        </div>
        <div>
          {/* <Button
            className="hk-products-header-copy-button"
            onClick={copyTemplateNames}
          >
            Copy Template Names
          </Button> */}
          <Button
            className="hk-header-button"
            type="primary"
            onClick={addProduct}
          >
            Add Product
          </Button>
        </div>
      </div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          rowKey={(record) => record.id}
          rowClassName="hk-table-row"
          loading={{ spinning: loading, size: 'large' }}
          columns={columns}
          dataSource={pagedProductList?.items || []}
          pagination={{
            defaultCurrent: 1,
            total: pagedProductList?.total,
            pageSize: pagedProductList?.per_page,
            showSizeChanger: false,
            onChange: handlePageChange
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                selectProduct(record);
              }
            };
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default ProductList;
