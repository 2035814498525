import * as React from 'react';
import { Form, Input, Select, Switch, Tooltip } from 'antd';
import {
  AttributeSchema,
  Attribute,
  HKActionType,
  IObjectKeys,
  PagedResults,
  ValueType
} from '../types';
import { GlobalOutlined, SearchOutlined } from '@ant-design/icons';

const { TextArea } = Input;

// Form types
export enum FormFieldCollection {
  Common,
  Attribute
}

export enum FormFieldControl {
  Input,
  InputPassword,
  Textarea,
  Select,
  Switch,
  Hidden,
  SelectSearch
}

export interface SelectOption {
  label: string;
  value: string | number;
}

export interface SearchRouteParams extends IObjectKeys {
  term?: string;
  typed?: string;
}

export interface AttributeField extends FormField {
  initialValue?: string | number | boolean;
}

export interface FormField {
  name: string;
  label: string;
  type?: ValueType;
  placeholder?: string;
  control: FormFieldControl;
  collection?: FormFieldCollection;
  disabled?: boolean;
  searchLoading?: boolean;
  searchResolver?: (field: FormField, term: string) => void;
  searchResultsFormatter?: (list: PagedResults) => SelectOption[];
  searchRoute?: string;
  searchRouteParams?: SearchRouteParams;
  searchTerm?: string;
  options?: SelectOption[];
  rules?: object[];
}

export interface DisabledFormFieldConfig {
  [HKActionType.Add]?: string[];
  [HKActionType.Edit]?: string[];
}

export const globeIconPrefix = (
  <GlobalOutlined style={{ fontSize: 24, margin: '8px', color: '#ccc' }} />
);

export const searchIconPrefix = (
  <SearchOutlined style={{ fontSize: 24, margin: '8px' }} />
);

export const DYNAMIC_FIELD_PREFIX = 'dynamic-field-';

export const stripDynamicFieldNamePrefix = (fieldName: string): string => {
  return fieldName.replace(DYNAMIC_FIELD_PREFIX, '');
};

export const buildFieldListForSchemaArray = (
  schemaList?: AttributeSchema[],
  attributes?: Attribute
): AttributeField[] | null => {
  if (!!schemaList) {
    const schemaFields: AttributeField[] = [];
    schemaList.forEach((schema) => {
      schemaFields.push({
        name: DYNAMIC_FIELD_PREFIX + schema.field,
        label: schema.label,
        initialValue: !!attributes
          ? (attributes[schema.field] as string | number | boolean)
          : undefined,
        type: schema.type,
        control: FormFieldControl.Input,
        collection: FormFieldCollection.Attribute
      });
    });
    return schemaFields;
  }
  return null;
};

const isFieldDisabled = (
  field: FormField,
  action: HKActionType,
  config: DisabledFormFieldConfig
): boolean => {
  if (!!config && config[action]) {
    return (config[action] as string[]).includes(field.name);
  }
  return false;
};

export const getFormControl = (
  field: FormField,
  action: string,
  disabledFieldConfig: DisabledFormFieldConfig
) => {
  switch (field.control) {
    case FormFieldControl.InputPassword:
      return (
        <Form.Item
          key={field.name}
          name={field.name}
          label={field.label}
          rules={field.rules || []}
        >
          <Input.Password size="large" />
        </Form.Item>
      );
    case FormFieldControl.Switch:
      return (
        <Form.Item
          key={field.name}
          name={field.name}
          label={field.label}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      );
    case FormFieldControl.Textarea:
      return (
        <Form.Item
          key={field.name}
          name={field.name}
          label={field.label}
          rules={field.rules || []}
        >
          <TextArea
            rows={4}
            size="large"
            disabled={isFieldDisabled(
              field,
              action as HKActionType,
              disabledFieldConfig
            )}
          />
        </Form.Item>
      );
    case FormFieldControl.Hidden:
      return (
        <Form.Item
          hidden={true}
          key={field.name}
          name={field.name}
          label={field.label}
        >
          <Input type="hidden" />
        </Form.Item>
      );
    case FormFieldControl.Select:
      return (
        <Form.Item
          key={field.name}
          name={field.name}
          label={field.label}
          rules={field.rules || []}
        >
          <Select
            showSearch
            size="large"
            placeholder={field.placeholder}
            disabled={isFieldDisabled(
              field,
              action as HKActionType,
              disabledFieldConfig
            )}
            filterOption={(input, option) =>
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              false
            }
          >
            {field.options?.map((opt, i) => {
              return (
                <Select.Option key={`${field.name}-${i}`} value={opt.value}>
                  {opt.label}
                </Select.Option>
              );
            })}
            ;
          </Select>
        </Form.Item>
      );
    case FormFieldControl.SelectSearch:
      return (
        <Form.Item
          key={field.name}
          name={field.name}
          label={field.label}
          rules={field.rules || []}
        >
          <Select
            showSearch
            showAction={['focus', 'click']}
            size="large"
            filterOption={false}
            placeholder={field.placeholder}
            loading={field.searchLoading}
            disabled={isFieldDisabled(
              field,
              action as HKActionType,
              disabledFieldConfig
            )}
            onSearch={(e) => {
              if (!!field.searchResolver) {
                field.searchResolver(field, e);
              }
            }}
            notFoundContent={<div>No matches found</div>}
          >
            {/** field.searchLoading ? <Spin size="small"/> :*/}
            {field.options?.map((opt, i) => {
              return (
                <Select.Option key={`${field.name}-${i}`} value={opt.value}>
                  <Tooltip title={opt.label} color={'#000'}>
                    {opt.label}
                  </Tooltip>
                </Select.Option>
              );
            })}
            ;
          </Select>
        </Form.Item>
      );
    default:
      return (
        <Form.Item
          key={field.name}
          name={field.name}
          label={field.label}
          rules={field.rules || []}
        >
          <Input
            size="large"
            disabled={
              field.disabled ||
              isFieldDisabled(
                field,
                action as HKActionType,
                disabledFieldConfig
              )
            }
          />
        </Form.Item>
      );
  }
};
