import { createApiRequest } from '../../util/api';
import {
  addAssetError,
  addAssetSuccess, AssetListURLConfig,
  deleteAssetError,
  deleteAssetSuccess, startAssetAction,
  updateAssetFailure,
  updateAssetSuccess
} from './AssetListSlice';
import { Asset } from '../../types';

export const addAsset = (urlConfig: AssetListURLConfig, body: object) => createApiRequest({
  route: urlConfig.listURL,
  replace: [urlConfig.replaceParams],
  method: 'post',
  body: body,
  onStart: startAssetAction,
  onSuccess: addAssetSuccess,
  onError: addAssetError,
  defaultErrorMessage: 'There was an error adding your image.'
})();

export const deleteAsset = (urlConfig: AssetListURLConfig, id: number) => createApiRequest({
  route: urlConfig.detailURL,
  method: 'delete',
  replace: [
    [...urlConfig.replaceParams],
    ['asset_id', id]
  ],
  onStart: startAssetAction,
  onSuccess: deleteAssetSuccess,
  onError: deleteAssetError,
  defaultErrorMessage: 'There was an error deleting your image.'
})();

export const updateAsset = (urlConfig: AssetListURLConfig, asset: Asset) => createApiRequest({
  route: urlConfig.detailURL,
  method: 'patch',
  replace: [
    [...urlConfig.replaceParams],
    ['asset_id', asset.id]
  ],
  body: asset,
  onStart: startAssetAction,
  onSuccess: updateAssetSuccess,
  onError: updateAssetFailure,
  defaultErrorMessage: 'There was an error updating your image.'
})();

