import React, { useEffect, useState } from "react";
import { HKActionType, IObjectKeys } from '../../types';
import { Form } from 'antd';
import {
  AttributeField,
  FormField,
  FormFieldCollection,
  FormFieldControl,
  getFormControl
} from '../../util/form-helpers';
import useDebounce from '../../hooks/Debouce';
import useAxiosFetch, { AxoisFetchConfig } from '../../hooks/AxiosFetch';
import { SEARCH_DELAY } from '../../util/constants';
import './AdminForm.scss'

export interface HKFormConfig {
  fields: FormField[];
  action: HKActionType;
  initialValues: object;
  disabledFieldsForAction: { [index: string]: string[] }
}

export const defaultFormLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

type AdminFormValues = {
  [key: string]: string|number
}

type AdminFormProps = {
  config: HKFormConfig,
  attributeFields?: AttributeField[]|null,
  layout?: object,
  onFieldChanged: (name: string, value: string|number) => void,
  onFinish: (values: {}) => void
};

const AdminForm: React.FC<AdminFormProps> = ({ config ,
                                                attributeFields,
                                                layout = defaultFormLayout,
                                                onFieldChanged,
                                                onFinish,
                                                children}) => {
  const [ form ] = Form.useForm();
  // state to track the current form data that will be displayed
  const [ formConfig, setCurrentFormConfig ] = useState(config);

  const [ searchConfig, setSearchConfig ] = useState<AxoisFetchConfig|undefined>();
  const debouncedSearchConfig = useDebounce(searchConfig, SEARCH_DELAY);
  const { pagedResults } = useAxiosFetch(debouncedSearchConfig);

  const valuesChanged = (changedValues: AdminFormValues, allValues: AdminFormValues) => {
    Object.keys(changedValues).forEach(key => { onFieldChanged(key, changedValues[key]) });
  }

  const updateFormConfig = () => {
    setCurrentFormConfig((formConfig) => {
      return Object.assign({}, formConfig);
    });
  }

  const onSubmit = (e: any) => {
    onFinish(e);
  };

  const debounceSearch = (field: FormField, term: string) => {
    if (term.length < 2) { return }
    let params = field.searchRouteParams || {};
    params.term = term;
    setSearchConfig({
      fieldName: field.name,
      apiRoute: field.searchRoute!,
      apiParams: params }
    );
  }

  const updateSelectLoadingFlag = (debouncedConfig: AxoisFetchConfig) => {
    let select = formConfig.fields.find(f => f.name === debouncedConfig.fieldName);
    if (!!select) {
      select.searchLoading = true
      updateFormConfig()
    }
  }

  useEffect(() => {
    if (!!pagedResults && !!searchConfig) {
      const select = formConfig.fields.find(f => f.name === searchConfig?.fieldName);
      if (!!select && select.searchResultsFormatter) {
        select.options = select.searchResultsFormatter(pagedResults);
        select.searchLoading = false;
        setSearchConfig(undefined);
        updateFormConfig();
      }
    }
  }, [pagedResults]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (debouncedSearchConfig) {
      updateSelectLoadingFlag(debouncedSearchConfig)
    }
  }, [debouncedSearchConfig]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!attributeFields) {
      const fields = [...formConfig.fields?.filter(field => field.collection === FormFieldCollection.Common),
                      ...attributeFields];
      setCurrentFormConfig((formConfig) => {
        formConfig.fields = fields
        return Object.assign({}, formConfig);
      });
      const dynamicValues: IObjectKeys = {};
      attributeFields.forEach(field => dynamicValues[field.name] = field.initialValue);
      form.setFieldsValue(dynamicValues);
    }
  }, [attributeFields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.setFieldsValue(config.initialValues);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form form={form} {...layout} onValuesChange={valuesChanged} onFinish={onSubmit} className="hk-admin-form">
      { formConfig.fields
        .map((field) => {
          if (field.control === FormFieldControl.SelectSearch) {
            field.searchResolver = debounceSearch.bind(this)
          }
          return getFormControl(field, formConfig.action, formConfig.disabledFieldsForAction);
        })
      }
      { children }
    </Form>
  );
};

export default AdminForm;
