import React from 'react';
import SideMenu from '../side-menu/SideMenu';
import './Layout.scss'
import { useSelector } from 'react-redux';
import { RootState } from '../../store';


const Layout: React.FC = ({ children }) => {
  const { loggedIn } = useSelector((state: RootState) => state.login);

  const SideMenuLayout = (children: any) => {
    return (
      <div className="hk-layout">
      <SideMenu/>
      <div className="hk-layout-content">
        { children }
      </div>
    </div>
    );
  }

  const LoginLayout = (children: any) => {
    return (
      <>
        { children }
      </>
    );
  }

  return (
    <>
      {loggedIn ? SideMenuLayout(children) : LoginLayout(children) }
    </>
  );
}

export default Layout;
