
import {
  BASE_REM_VAL,
  LOCAL, PRODUCTION,
  TWO_SECONDS
} from './constants';
import EmptyBox from '../../assets/illustrations/empty-box.svg';
import * as React from 'react';

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
}

export const remToPx = (rem: string): number => {
  let n = 0;
  if (rem && typeof rem === 'string') {
    n = Number(rem.replace('rem', '')) * BASE_REM_VAL;
  }
  return n;
}

export const replaceParams = (route: string, params: Array<Array<any>> | Array<any>): string => {
  let newRoute = route;

  const replace = (p: Array<any>) => {
    newRoute = newRoute.replace(`{${p[0]}}`, String(p[1]));
  }

  if (Array.isArray(params[0])) {
    params.forEach(p => replace(p));
  } else {
    replace(params);
  }

  return newRoute;
}

export const getEnvName = () => process.env.REACT_APP_ENV_NAME;

export const getBaseApiUrl = () => {
  const { REACT_APP_API_PROTOCOL, REACT_APP_API_URL, REACT_APP_API_PORT } = process.env;

  const apiBaseUrl = (
    (REACT_APP_API_PROTOCOL || 'https') + '://'
    + REACT_APP_API_URL
    + (REACT_APP_API_PORT ? `:${REACT_APP_API_PORT}` : '')
  );

  return apiBaseUrl;
}

export const isDev = () => {
  const env = getEnvName();
  return env === LOCAL
};

export const isProd = () => {
  const env = getEnvName();
  return env === PRODUCTION
};

export const isFunction = (f: any) => f && typeof f === 'function';

export const sleep = (t = TWO_SECONDS) =>
  new Promise(resolve => setTimeout(resolve, t));

export const translateCreateContactError = (error: string): string => {
  switch (error) {
    case 'Prospect already exists': return 'This email is already associated with another account.';
    default: return error;
  }
}

export const copyJSON = (element: object): object => {
  return JSON.parse(JSON.stringify(element));
}

export const formatAddress = (home?: any) => {
  let address = '';
  if (!!home) {
    if (!!home.address1) { address += (home.address1 + ', ') }
    if (!!home.address2) { address += (home.address2 + ', ') }
    if (!!home.city)  { address += (home.city + ', ')}
    if (!!home.state) { address += (home.state + ' ')}
    if (!!home.postal_code)   { address += home.postal_code}
  }
  return address;
}

export const removeUnderscore = (value: string): string => {
  return value.replace('_', ' ');
}

export const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center', margin: '120px 8px' }}>
    <img src={EmptyBox} style={{ height: 40 }} alt="No items found"/>
    <p style={{marginTop: '8px'}}>No items found</p>
  </div>
);

export const removeItemFromArrayById = (items: {id:number}[], id: number|string): object[] => {
  const itemToRemove = items.find(i => i.id === id);
  if (!!itemToRemove) { items.splice(items.indexOf(itemToRemove), 1); }
  return items;
}

export const propComparator = (propName: string) =>
  (a: { [index: string]: string|number }, b: { [index: string]: string|number }) => {
    if (typeof a[propName] === 'number' && typeof b[propName] === 'number')  {
      return Number(a[propName]) - Number(b[propName]);
    } else if (typeof a[propName] === 'string' && typeof b[propName] === 'string') {
      const aString = String(a[propName]).toLocaleLowerCase();
      const bString = String(b[propName]).toLocaleLowerCase()
      return aString === bString ? 0 : aString < bString ? -1 : 1
    }
    return 0;
  }
