import { createApiRequest } from '../../core/util/api';
import { PRODUCT_DETAIL_URL, PRODUCT_LIST_URL } from '../../core/util/constants';
import {
  createProductError,
  createProductStart,
  createProductSuccess,
  fetchProductError,
  fetchProductStart,
  fetchProductSuccess
} from './ProductDetailSlice';

export const fetchHomeProduct = (homeid: string, id: string) => createApiRequest({
  route: PRODUCT_DETAIL_URL,
  replace: [
    ['home_id', homeid],
    ['product_id', id]
  ],
  onStart: fetchProductStart,
  onSuccess: fetchProductSuccess,
  onError: fetchProductError,
  defaultErrorMessage: 'There was an error requesting this appointment.'
})();

export const postHomeProduct = (homeid: string, body: object) => createApiRequest({
  route: PRODUCT_LIST_URL,
  replace: ['home_id', homeid],
  method: 'post',
  body: body,
  onStart: createProductStart,
  onSuccess: createProductSuccess,
  onError: createProductError,
  defaultErrorMessage: 'There was an error requesting this appointment.'
})();

export const patchHomeProduct = (homeid: string, id: number, body: object) => createApiRequest({
  route: PRODUCT_DETAIL_URL,
  replace: [
    ['home_id', homeid],
    ['product_id', id]
  ],
  method: 'patch',
  body: body,
  onStart: createProductStart,
  onSuccess: createProductSuccess,
  onError: createProductError,
  defaultErrorMessage: 'There was an error requesting this appointment.'
})();