import { Contact } from '../../types';
import { Card, Popover } from 'antd';
import { formatPhoneNumber } from '../../util/helpers';
import * as React from 'react';
import { useEffect, useState } from 'react';
import './PopoverCard.scss'

type PopoverCardProps = {
  contact: Contact;
};

enum ContactFieldType {
  Phone,
  Email
}

interface ContactField {
  label: string;
  value: string;
  type: ContactFieldType
}

const PopoverCard: React.FC<PopoverCardProps> = ({contact}) => {

  const [fields, setFields] = useState<ContactField[]|null>(null);

  useEffect(() => {
    if (!fields) {
      const fieldArray: ContactField[] = [];
      if (!!contact.home_phone) {
        fieldArray.push({ label: 'Home', value: contact.home_phone, type: ContactFieldType.Phone})
      }
      if (!!contact.mobile_phone) {
        fieldArray.push({ label: 'Mobile', value: contact.mobile_phone, type: ContactFieldType.Phone})
      }
      if (!!contact.work_phone) {
        fieldArray.push({ label: 'Work', value: contact.work_phone, type: ContactFieldType.Phone})
      }
      if (!!contact.email) {
        fieldArray.push({ label: 'Email', value: contact.email, type: ContactFieldType.Email})
      }
      setFields(fieldArray)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Popover
      overlayClassName="hk-contact-popover"
      arrowPointAtCenter
      trigger={'click'}
      content={
        <Card
          title={ contact.name }
          bordered={ false }
          style={{ width: 440 }}
          className="hk-contact-popover-card">
          { fields?.map((field, index) => {
            return (
              <span key={`row-${index}`}>
                <Card.Grid
                  key={`row-${index}-label`}
                  className="hk-popover-card-label"
                  hoverable={false}>
                  {field.label}:
                </Card.Grid>
                <Card.Grid
                  key={`row-${index}-value`}
                  className="hk-popover-card-value"
                  hoverable={false}>
                  { field.type === ContactFieldType.Phone
                    ?
                    <span>{ formatPhoneNumber(field.value) }</span>
                    :
                    <a href={`mailto:${ field.value }`}>{ field.value }</a>
                  }
                </Card.Grid>
              </span>
            )
          })}
        </Card>
      }>
      <span className="hk-contact-popover-link">{ contact.name }</span>
    </Popover>
  );
};

export default PopoverCard;
