import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store';
import { useParams } from 'react-router';
import { Breadcrumb, Button, Collapse, Space, Spin } from 'antd';
import { CheckOutlined, UndoOutlined } from '@ant-design/icons';
import { copyJSON, formatAddress } from '../../core/util/helpers';
import {
  fetchAppointment,
  fetchAppointmentTasks,
  fetchAppointmentWorker,
  fetchStatusTags,
  updateAllTasks,
  updateAppointment
} from './AppointmentDetailActions';
import {
  resetAppointment,
  resetTaskList,
  updateAppointmentInState
} from './AppointmentDetailSlice';
import {
  formattedDateForTimezone,
  formatTimestampForGMT
} from '../../core/util/timezone';
import { fetchHome, fetchHomeContacts } from '../home/HomeActions';
import {
  APPOINTMENT_ASSET_DETAIL_URL,
  APPOINTMENT_ASSET_LIST_URL
} from '../../core/util/constants';
import { Appointment, HKAssetType, Task } from '../../core/types';
import AppointmentTask from '../task/Task';
import AssetList from '../../core/components/asset-list/AssetList';
import Notes from '../../core/components/notes/Notes';
import './AppointmentDetail.scss';
import PopoverCard from '../../core/components/popover-card/PopoverCard';
import { NavLink } from 'react-router-dom';

const { Panel } = Collapse;

interface AppointmentDetailsParams {
  homeid?: string | undefined;
  id?: string | undefined;
}

const AppointmentDetail: React.FC = () => {
  const { id } = useParams<AppointmentDetailsParams>();
  const { selectedHome, contactList } = useSelector(
    (state: RootState) => state.home
  );
  const {
    loadingAppointment,
    appointment,
    statusTags,
    loadingTaskList,
    pagedTaskList,
    appointmentUser
  } = useSelector((state: RootState) => state.appointmentDetail);
  const dispatch = useDispatch();
  const closeNotesRef = useRef<() => void>();
  const closeAssetsRef = useRef<() => void>();

  const toggleAppointment = () => {
    if (!!appointment) {
      const appointmentCopy = copyJSON(appointment) as Appointment;
      appointmentCopy.finished = !!appointmentCopy.finished
        ? null
        : formatTimestampForGMT();
      dispatch(updateAppointment(appointmentCopy));
      if (!!closeNotesRef && !!closeNotesRef.current) {
        closeNotesRef.current();
      }
      if (!!closeAssetsRef && !!closeAssetsRef.current) {
        closeAssetsRef.current();
      }
    }
  };

  const completeRemainingTasks = () => {
    if (!!appointment) {
      const finished = formatTimestampForGMT();
      if (!!pagedTaskList) {
        const taskListCopy = copyJSON(pagedTaskList.items) as Task[];
        taskListCopy.forEach((task: Task) => {
          if (!task.finished) {
            task.finished = finished;
          }
        });
        dispatch(updateAllTasks(taskListCopy));
      }
    }
  };

  const onWorkerNotesChange = (notes: string) => {
    if (!!appointment) {
      const apptCopy = copyJSON(appointment) as Appointment;
      apptCopy.worker_notes = notes;
      dispatch(updateAppointmentInState(apptCopy));
    }
  };

  useEffect(() => {
    if (!!appointment && appointment.id !== Number(id)) {
      dispatch(resetAppointment());
    } else if (!!id && !appointment) {
      dispatch(fetchAppointment(id));
    }
  }, [dispatch, id, appointment]);

  useEffect(() => {
    if (!statusTags) {
      dispatch(fetchStatusTags());
    }
  }, [dispatch, statusTags]);

  useEffect(() => {
    if (!!appointment && !selectedHome) {
      dispatch(fetchHome(appointment.home_id));
      dispatch(fetchHomeContacts(appointment.home_id));
    }
  }, [dispatch, appointment, selectedHome]);

  useEffect(() => {
    if (!!appointment && !pagedTaskList) {
      dispatch(fetchAppointmentTasks(appointment.id));
      if (appointment.checked_out && !!appointment.checked_out_by) {
        dispatch(fetchAppointmentWorker(appointment.checked_out_by));
      }
    } else if (!!pagedTaskList && !!appointment) {
      const task = pagedTaskList.items[0];
      if (task.appointment_id !== appointment.id) {
        dispatch(resetTaskList());
      }
    }
  }, [dispatch, appointment, pagedTaskList]);

  const completeAllButton = (disabled: boolean) => (
    <Button
      className="hk-medium-button"
      type="primary"
      disabled={disabled}
      loading={loadingTaskList}
      onClick={completeRemainingTasks}
    >
      Complete Remaining Tasks
    </Button>
  );

  return (
    <div className="hk-appointment">
      <Breadcrumb className="hk-appointments-breadcrumbs" separator=">">
        <Breadcrumb.Item>
          <NavLink to={'/homes'}>
            Homes
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <NavLink
            to={`/home/${appointment?.home_id}/appointments`}
          >
            Appointments
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appointment?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="hk-appointment-header">
        <div className="hk-appointment-header-title">
          <h1>{appointment?.name}</h1>
          {appointment?.checked_out && !!appointmentUser && (
            <span className="hk-appointment-header-title-checkout-status">
              Checked out by: <PopoverCard contact={appointmentUser.contact} />
            </span>
          )}
        </div>
        <div className="hk-appointment-header-actions">
          {!!appointment &&
            (appointment?.finished ? (
              <Button
                className="hk-header-button"
                type="primary"
                danger
                disabled={appointment.checked_out}
                loading={!!appointment && loadingAppointment}
                icon={<UndoOutlined />}
                onClick={toggleAppointment}
              >
                Revert to Incomplete
              </Button>
            ) : (
              <Button
                className="hk-header-button"
                type="primary"
                disabled={appointment.checked_out}
                loading={!!appointment && loadingAppointment}
                icon={<CheckOutlined />}
                onClick={toggleAppointment}
              >
                Complete Appointment
              </Button>
            ))}
        </div>
      </div>
      {!!appointment && (
        <div className="hk-appointment-details">
          <Space size={36} direction="vertical">
            <Collapse bordered={false} defaultActiveKey={['1', '2']}>
              <Panel header="Appointment Details" key="1">
                <div className="hk-appointment-details-info">
                  {!!contactList && (
                    <div className="hk-appointment-details-info-homeowner">
                      {contactList.map((contact) => {
                        return (
                          <>
                            <PopoverCard contact={contact} />{' '}
                          </>
                        );
                      })}
                      {!!selectedHome && (
                        <div>{formatAddress(selectedHome)}</div>
                      )}
                    </div>
                  )}
                  <div className="hk-appointment-details-info-dates">
                    {!!appointment.finished && (
                      <div className="hk-appointment-details-info-dates-completed">
                        <h3>Completed</h3>
                        <div className="hk-appointment-details-info-dates-completed-timestamp">
                          {formattedDateForTimezone(
                            appointment.finished,
                            selectedHome?.market.timezone
                          )}
                        </div>
                      </div>
                    )}
                    <div className="hk-appointment-details-info-dates-scheduled">
                      <h3>Scheduled</h3>
                      <div className="hk-appointment-details-info-dates-scheduled-timestamp">
                        {formattedDateForTimezone(
                          appointment.scheduled,
                          selectedHome?.market.timezone
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Notes
                  title="Customer Notes"
                  notes={appointment?.homeowner_notes || ''}
                  editable={false}
                />
                <Notes
                  title="Maintenance Specialist Notes"
                  notes={appointment?.worker_notes || ''}
                  editable={!appointment?.checked_out}
                  onNotesUpdated={onWorkerNotesChange}
                  closeNotesRef={closeNotesRef}
                />
                {!!appointment && (
                  <div className="hk-appointment-assets">
                    <AssetList
                      assetTypes={[HKAssetType.Photos, HKAssetType.Videos]}
                      parent={appointment}
                      editable={!appointment?.checked_out}
                      preventDelete={true}
                      urlConfig={{
                        replaceParams: ['appt_id', `${appointment.id}`],
                        listURL: APPOINTMENT_ASSET_LIST_URL,
                        detailURL: APPOINTMENT_ASSET_DETAIL_URL
                      }}
                      updateParentInStateAction={updateAppointmentInState}
                      closeAssetsRef={closeAssetsRef}
                    />
                  </div>
                )}
              </Panel>
            </Collapse>
          </Space>
        </div>
      )}
      <div className="hk-appointment-tasks">
        <div className="hk-appointment-tasks-header">
          <h2>Appointment Tasks</h2>
          {completeAllButton(appointment?.checked_out || false)}
        </div>
        {!!pagedTaskList &&
          pagedTaskList.items.map((t) => {
            return (
              <AppointmentTask
                key={t.id}
                task={t}
                editable={!appointment?.checked_out}
              />
            );
          })}
      </div>
      {(loadingAppointment || loadingTaskList) && (
        <Spin className="hk-spinner" size="large" />
      )}
    </div>
  );
};

export default AppointmentDetail;
