import * as React from 'react';
import { Button, Input } from 'antd';
import { MutableRefObject, useEffect, useState } from 'react';
import './Notes.scss';

const { TextArea } = Input;

type NotesProps = {
  title: string;
  notes: string;
  editable: boolean;
  onNotesUpdated?: (notes: string) => void;
  closeNotesRef?: MutableRefObject<any>;
};

const Notes: React.FC<NotesProps> = ({ closeNotesRef,
                                       title,
                                       notes,
                                       editable,
                                       onNotesUpdated }) => {

  const [ currentValue, setCurrentValue ] = useState(notes);
  const [ editingNotes, setEditingNotes ] = useState(false);

  useEffect(() => {
    if (!!onNotesUpdated) {
      onNotesUpdated(currentValue)
    }
  }, [currentValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeNotes = () => {
    setEditingNotes(false);
  }

  useEffect(() => {
    if (!!closeNotesRef) {
      closeNotesRef.current = closeNotes;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="hk-notes">
      <h4>{ title }</h4>
      <div className="hk-notes-body">
        <div className="hk-notes-body-form">
          { !editingNotes &&
          <div className="hk-notes-body-form-text">
            {!!notes ? notes : (<div className="placholder">~</div>) }
          </div>
          }
          { editingNotes &&
            <TextArea
              className="hk-notes-body-form-textarea"
              rows={4}
              value={currentValue}
              onChange={(e)=>setCurrentValue(e.currentTarget.value)}/>
          }
        </div>
        <div className="hk-notes-body-actions">
          { editable &&
            <Button
              className="hk-action-button"
              onClick={()=>{ setEditingNotes(!editingNotes) } }>
              { editingNotes ? 'Close' : 'Edit' }
            </Button>
          }
        </div>
      </div>
    </div>
  );
};

export default Notes;
