import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
import { DATE_FORMAT_MONTH_DATE_HOURS_MINUTES, DATE_FORMAT_TIMESTAMP } from './constants';

export const formattedDateForTimezone = (timestamp: string, timezone?: string): string => {
  return !!timestamp && !!timezone ? formatLocalizedTimestamp(timestamp, timezone) : ''
}

export const formatTimestampForTimezone = (timezone: string, timestamp: string, formatString: string): string => {
  const date = new Date(timestamp);
  const zonedDate = utcToZonedTime(date, timezone);
  return format(zonedDate, formatString, { timeZone: timezone });
}

export const formatTimestampForGMT = () => {
  const todayInUTC = utcToZonedTime(new Date(), 'UTC');
  return format(todayInUTC, DATE_FORMAT_TIMESTAMP, { timeZone: 'UTC' });
}

export const formatLocalizedTimestamp = (timestamp: string, timezone: string): string => {
  const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localUTCDate = zonedTimeToUtc(timestamp, browserTz);
  const remoteTimezoneDate = changeTimezone(localUTCDate, timezone);
  return format(remoteTimezoneDate, DATE_FORMAT_MONTH_DATE_HOURS_MINUTES, { timeZone: timezone });
}

function changeTimezone(date: Date, ianatz: string) {
  // suppose the date is 12:00 UTC
  const invdate = new Date(date.toLocaleString('en-US', { timeZone: ianatz }));
  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime();
  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract
}