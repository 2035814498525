import { Appointment, PagedResults, StatusTag, Task, User } from '../../core/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PagedTaskList extends PagedResults {
  items: Task[]
}

interface AppointmentDetailState {
  loadingAppointment: boolean;
  loadingTaskList: boolean;
  error: string | null;
  appointment: Appointment | null;
  appointmentUser: User | null;
  pagedTaskList: PagedTaskList | null;
  statusTags: StatusTag[] | null;
  updatingTaskId: number | null;
}

export const initialState: AppointmentDetailState = {
  loadingAppointment: false,
  loadingTaskList: false,
  error: null,
  appointment: null,
  appointmentUser: null,
  pagedTaskList: null,
  statusTags: null,
  updatingTaskId: null
};

export const appointmentDetailSlice = createSlice({
  name: 'appointmentDetail',
  initialState,
  reducers: {
    fetchAppointmentStart: (state) => {
      state.loadingAppointment = true;
      state.appointment = null;
    },
    fetchAppointmentSuccess: (state, action: PayloadAction<any>) => {
      state.appointment = action.payload;
      state.loadingAppointment = false;
    },
    fetchAppointmentError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.appointment = null;
      state.loadingAppointment = false;
    },
    fetchAppointmentWorkerSuccess: (state, action: PayloadAction<any>) => {
      state.appointmentUser = action.payload;
    },
    fetchAppointmentWorkerError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fetchStatusTagsSuccess: (state, action: PayloadAction<any>) => {
      state.statusTags = action.payload;
    },
    fetchStatusTagsError: (state, action: PayloadAction<any>) => {
      state.statusTags = action.payload;
    },
    updateAppointmentInState: (state, action) => {
      state.appointment = action.payload;
    },
    updateAppointmentStart: (state) => {
      state.loadingAppointment = true;
    },
    updateAppointmentSuccess: (state, action: PayloadAction<any>) => {
      state.appointment = action.payload;
      state.loadingAppointment = false;
    },
    updateAppointmentError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loadingAppointment = false;
    },
    resetAppointment: (state) => {
      state.appointment = null;
    },
    updateTaskStart: (state, action) => {
      state.updatingTaskId = action.payload
    },
    updateTaskSuccess: (state, action) => {
      state.updatingTaskId = null;
      const updatedTask = action.payload;
      const task = state.pagedTaskList?.items.find(t => t.id === updatedTask.id);
      if (!!task) {
        state.pagedTaskList?.items.splice(state.pagedTaskList?.items.indexOf(task), 1, updatedTask);
      }
    },
    updateTaskError: (state, action) => {
      state.error = action.payload;
      state.updatingTaskId = null;
    },
    // used by the AssetList component to update the Task asset list
    updateTaskInState: (state, action) => {
      const updatedTask = action.payload;
      const task = state.pagedTaskList?.items.find(t => t.id === updatedTask.id);
      if (!!task) {
        state.pagedTaskList?.items.splice(state.pagedTaskList?.items.indexOf(task), 1, updatedTask);
      }
    },
    fetchTaskListStart: (state) => {
      state.pagedTaskList = null;
      state.loadingTaskList = true;
    },
    fetchTaskListSuccess: (state, action: PayloadAction<PagedTaskList>) => {
      state.pagedTaskList = action.payload;
      state.loadingTaskList = false;
    },
    fetchTaskListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loadingTaskList = false;
    },
    resetTaskList: (state) => {
      state.pagedTaskList = null;
      state.loadingTaskList = false;
      state.error = null;
    },
    updateTaskListStart: (state, action: PayloadAction<any>) => {
      state.loadingTaskList = true;
    },
    updateTaskListSuccess: (state, action: PayloadAction<Task[]>) => {
      if (!!state.pagedTaskList) {
        state.pagedTaskList.items = action.payload;
      }
      state.loadingTaskList = false;
    },
    updateTaskListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loadingTaskList = false;
    }
  }
});

export const {
  fetchAppointmentStart,
  fetchAppointmentSuccess,
  fetchAppointmentError,
  fetchAppointmentWorkerSuccess,
  fetchAppointmentWorkerError,
  fetchStatusTagsSuccess,
  fetchStatusTagsError,
  updateAppointmentInState,
  resetAppointment,
  updateAppointmentStart,
  updateAppointmentSuccess,
  updateAppointmentError,
  fetchTaskListStart,
  fetchTaskListSuccess,
  fetchTaskListError,
  resetTaskList,
  updateTaskInState,
  updateTaskStart,
  updateTaskSuccess,
  updateTaskError,
  updateTaskListStart,
  updateTaskListSuccess,
  updateTaskListError,
} = appointmentDetailSlice.actions;

export default appointmentDetailSlice.reducer;
