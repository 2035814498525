import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { RootState } from 'core/store';

interface PrivateRouteProps {
  exact?: boolean;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const { loggedIn } = useSelector((state: RootState) => state.login);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
