import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store';
import {
  Breadcrumb,
  Button,
  Form,
  message,
  Modal,
  Spin,
  Tooltip,
  Image
} from 'antd';
import {
  FilePdfOutlined,
  LoadingOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import {
  Asset,
  Attribute,
  AttributeSchema,
  HKActionType,
  HKAssetType,
  IObjectKeys,
  PagedResults,
  ProductModel,
  ValueType
} from '../../core/types';
import {
  PRODUCT_ASSET_DETAIL_URL,
  PRODUCT_ASSET_LIST_URL,
  PRODUCT_MODEL_LIST_URL
} from '../../core/util/constants';
import { resetProductList } from './ProductListSlice';
import {
  fetchHomeProduct,
  patchHomeProduct,
  postHomeProduct
} from './ProductDetailActions';
import {
  AttributeField,
  buildFieldListForSchemaArray,
  FormFieldCollection,
  FormFieldControl,
  SelectOption,
  stripDynamicFieldNamePrefix
} from '../../core/util/form-helpers';
import { removeProduct, updateProduct } from './ProductDetailSlice';
import { formatTimestampForGMT } from '../../core/util/timezone';
import AssetList from '../../core/components/asset-list/AssetList';
import './ProductDetail.scss';
import {
  PagedProductModelResults,
  searchProductModelsSuccess
} from '../models/ProductModelListSlice';
import AdminForm, {
  defaultFormLayout,
  HKFormConfig
} from '../../core/components/admin-form/AdminForm';
import { fetchProductModel } from '../models/ProductModelDetailActions';
import {
  resetModelDetail,
  resetProductDetail
} from '../models/ProductModelDetailSlice';
import { formatAddress } from 'core/util/helpers';
import { fetchHome, fetchHomeContacts } from 'features/home/HomeActions';
import ProductDetailModelModal from './ProductDetailModelModal';
import { NavLink } from 'react-router-dom';

interface ProductDetailParams {
  homeid: string;
  action: string;
  id?: string | undefined;
}

const PRODUCT_MODEL_FIELD_NAME = 'product_template_id';

const ProductDetail: React.FC = () => {
  const { homeid, action, id } = useParams<ProductDetailParams>();
  const { selectedHome, contactList } = useSelector(
    (state: RootState) => state.home
  );
  const { product, redirectToPattern } = useSelector(
    (state: RootState) => state.productDetail
  );
  const { detailLoading, model } = useSelector(
    (state: RootState) => state.modelDetail
  );
  const { pagedModelList } = useSelector((state: RootState) => state.modelList);

  const [copiedModelName, setCopiedModelName] = useState('');
  const [copiedAssets, setCopiedAssets] = useState<Asset[]>([]);
  const [copiedId, setCopiedId] = useState<number>();
  const [messageApi, contextHolder] = message.useMessage();
  const [formConfigured, setFormConfigured] = useState(false);
  const [attributeFields, setAttributeFields] = useState<
    AttributeField[] | null
  >(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const copyFieldName = () => {
    const modelName = !!copiedModelName
      ? copiedModelName
      : product?.product_template?.name!;
    navigator.clipboard.writeText(modelName);
    messageApi.info('Copied!');
  };

  const showProductModelModal = () => {
    setIsModalOpen(true);
  };

  const handleModalSubmit = () => {
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const getFormConfig = (): HKFormConfig => {
    let config = formConfig;
    config.initialValues = getInitialFormValues();
    // set a single option for each of the fields which will be disabled on display
    const modelField = config.fields.find(
      (i) => i.name === PRODUCT_MODEL_FIELD_NAME
    );
    if (!!product && !!modelField) {
      // add model type filtering for edits
      modelField.searchRouteParams = {
        typed: product.product_template?.type
      };
      modelField.options = [
        {
          label: product.product_template?.name || '',
          value: String(product.product_template?.id)
        }
      ];
    }
    return config;
  };

  const getInitialFormValues = () => {
    if (action === HKActionType.Add && !!model) {
      let values: IObjectKeys = {};
      return values;
    }
    if (action === HKActionType.Edit && !!product) {
      let values: IObjectKeys = {
        name: product?.name,
        serial: product?.serial,
        registered: !!product?.registered,
        product_template_id: String(product.product_template?.id) || 0 //isGenericProduct() ? undefined :
      };
      return values;
    }
    return {};
  };

  const parseFormValuesToJSONBody = (values: any): object => {
    const { name, serial, registered, product_template_id } = values;
    const attributes: Attribute = {};
    if (!!attributeFields) {
      attributeFields.forEach((field) => {
        attributes[stripDynamicFieldNamePrefix(field.name)] =
          field?.type === ValueType.Number
            ? Number(values[field.name])
            : values[field.name];
      });
    }
    return {
      attributes: attributes,
      home_id: homeid,
      name: name,
      serial: serial,
      registered: registered ? formatTimestampForGMT() : null,
      type: !!product ? product.type : 'default',
      product_template_id: product_template_id
    };
  };

  const onFinish = (values: any) => {
    const body: any = parseFormValuesToJSONBody(values);
    if (action === HKActionType.Add) {
      if (copiedAssets.length > 0) {
        body['asset_ids'] = copiedAssets.map((asset) => asset.id);
      }
      if (copiedId) {
        body['copy_of'] = copiedId;
      }
      dispatch(postHomeProduct(homeid, body));
    }
    if (action === HKActionType.Edit && !!product) {
      dispatch(patchHomeProduct(homeid, product.id, body));
    }
  };

  const handleFieldChanged = (name: string, value: string | number) => {
    if (name === PRODUCT_MODEL_FIELD_NAME) {
      if (!!pagedModelList) {
        const model = pagedModelList.items.find((t) => t.id === Number(value));
        if (!!model) {
          setCopiedModelName(model.name);
          dispatch(fetchProductModel(String(model.id)));
        }
      }
    }
  };

  const addProductAttributeFieldsForSchema = (
    schema?: AttributeSchema[],
    attributes?: Attribute
  ) => {
    const fields = [
      ...(buildFieldListForSchemaArray(schema, attributes) || []),
      ...getModelAttributeFields()
    ];
    setAttributeFields(fields);
  };

  const getModelAttributeFields = (): AttributeField[] => {
    let modelSchema: AttributeSchema[] = [];
    let attributes;
    // if a model is present, the product type select has been changed
    // it's attributes and schema have priority
    if (!!model && !!model.type_data) {
      modelSchema = model.type_data.attribute_schema;
      attributes = model.attributes;
    } else if (!!product && !!product.product_template) {
      modelSchema = product.product_template.type_data.attribute_schema;
      attributes = product.product_template.attributes;
    }
    const fields = buildFieldListForSchemaArray(modelSchema, attributes) || [];
    fields.map((field) => (field.disabled = true));
    return fields;
  };

  const searchProductModelOptionsFormatter = (
    list: PagedResults
  ): SelectOption[] => {
    dispatch(resetModelDetail());
    dispatch(searchProductModelsSuccess(list as PagedProductModelResults));
    return (list as PagedProductModelResults).items.map(
      (model: ProductModel) => {
        return { label: model.name, value: model.id };
      }
    );
  };

  const copyAssets = () => {
    if (product) {
      setCopiedId(product.id);
      setCopiedAssets(product.assets);
      dispatch(removeProduct());
      dispatch(resetProductDetail());
      dispatch(resetModelDetail());
      setAttributeFields(null);
      setFormConfigured(false);
      history.push(`/home/${homeid}/product/add`);
    }
  };

  useEffect(() => {
    if (!!redirectToPattern) {
      dispatch(resetProductList());
      history.push(redirectToPattern.replace('{home_id}', homeid));
    }
  }, [dispatch, history, redirectToPattern]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    addProductAttributeFieldsForSchema(
      !!model ? model.type_data.product_attribute_schema : undefined
    );
  }, [model]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!product && product.id !== Number(id)) {
      dispatch(removeProduct());
    }
    if (!!id && !product) {
      dispatch(fetchHomeProduct(homeid, id));
      dispatch(fetchHomeContacts(homeid));
    }
  }, [dispatch, id, product]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!formConfigured) {
      if (action === HKActionType.Add) {
        setFormConfigured(true);
      } else if (action === HKActionType.Edit && !!product) {
        addProductAttributeFieldsForSchema(
          product.product_template?.type_data.product_attribute_schema,
          product.attributes
        );
        setFormConfigured(true);
      }
    }
  }, [product, formConfigured]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(resetProductDetail());
    dispatch(resetModelDetail());
    setAttributeFields(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedHome) {
      dispatch(fetchHome(Number(homeid)));
    }
  }, [dispatch, homeid, selectedHome]);

  const formConfig: HKFormConfig = {
    action: action as HKActionType,
    initialValues: {},
    disabledFieldsForAction: {}, //[HKActionType.Edit]: [PRODUCT_MODEL_FIELD_NAME]
    fields: [
      {
        name: PRODUCT_MODEL_FIELD_NAME,
        label: 'Product Model',
        placeholder: 'Search product models',
        control: FormFieldControl.SelectSearch,
        collection: FormFieldCollection.Common,
        searchRoute: PRODUCT_MODEL_LIST_URL,
        searchResultsFormatter: searchProductModelOptionsFormatter,
        rules: [{ required: true, message: 'Missing product type' }]
      },
      {
        name: 'name',
        control: FormFieldControl.Input,
        collection: FormFieldCollection.Common,
        label: 'Product Name',
        rules: [{ required: true, message: 'Missing name' }]
      },
      {
        name: 'serial',
        label: 'Serial Number',
        control: FormFieldControl.Input,
        collection: FormFieldCollection.Common
      },
      {
        name: 'registered',
        label: 'Registered',
        control: FormFieldControl.Switch,
        collection: FormFieldCollection.Common
      }
    ]
  };

  return (
    <>
      <div className="hk-product-detail">
        <Breadcrumb className="hk-product-detail-breadcrumbs" separator=">">
          <Breadcrumb.Item>
            <NavLink to={'/homes'}>Homes</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink to={`/home/${homeid}/products`}>Home Products</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {!!action && action === HKActionType.Add ? 'Add' : 'Edit'} Product
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="hk-product-detail-header">
          <div className="hk-product-detail-header-title">
            <h1>
              {!!action && action === HKActionType.Add ? 'Add' : 'Edit'} Product
            </h1>
            {selectedHome && <span>{formatAddress(selectedHome)}</span>}
            <p>
              <>
                {contactList?.map(
                  (contact, index) =>
                    `${contact.name}${index !== contactList.length - 1 ? ', ' : ' - '}`
                )}
              </>
              <>ID: {selectedHome?.id}</>
            </p>
          </div>
          <div>
            {contextHolder}
            <Button
              className="hk-product-detail-header-copy-button"
              onClick={copyFieldName}
            >
              Copy Model Name
            </Button>
            <Button
              className="hk-product-detail-header-copy-button"
              onClick={showProductModelModal}
            >
              Add Product Model
            </Button>
            <Button className="hk-header-button" type="primary">
              <label htmlFor="submit-form" className="hk-header-button-label">
                Save
              </label>
            </Button>
          </div>
        </div>

        {!formConfigured && <Spin className="hk-spinner" size="large" />}
        {formConfigured && (
          <AdminForm
            config={getFormConfig()}
            attributeFields={attributeFields}
            onFieldChanged={handleFieldChanged}
            onFinish={onFinish}
          >
            {!!product && !!product.assets && (
              <Form.Item label="Media">
                <AssetList
                  assetTypes={[HKAssetType.Photos, HKAssetType.Videos]}
                  parent={product}
                  allowDefaultToggle={true}
                  urlConfig={{
                    replaceParams: ['prod_id', `${product.id}`],
                    listURL: PRODUCT_ASSET_LIST_URL,
                    detailURL: PRODUCT_ASSET_DETAIL_URL
                  }}
                  updateParentInStateAction={updateProduct}
                  showTitle={false}
                  showCopy={action === HKActionType.Edit}
                  onCopyClick={copyAssets}
                />
              </Form.Item>
            )}
            {copiedAssets.length > 0 && (
              <Form.Item label="Copied Media">
                {copiedAssets.map((asset) => (
                  <span key={asset.id}>
                    {(asset.type === HKAssetType.Photos ||
                      asset.type === HKAssetType.Default) && (
                      <Image
                        width={100}
                        height={100}
                        src={asset.thumb_cloud_store || asset.file_url}
                      />
                    )}
                    {asset.type === HKAssetType.Videos && (
                      <span className="hk-asset-list-items-item-media-icon">
                        <VideoCameraOutlined />
                      </span>
                    )}
                    {asset.type === HKAssetType.Manuals && (
                      <Tooltip placement="topLeft" title={asset.name}>
                        <a
                          href={asset.file_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="hk-asset-list-items-item-media-icon">
                            <FilePdfOutlined />
                          </span>
                        </a>
                      </Tooltip>
                    )}
                  </span>
                ))}
              </Form.Item>
            )}
            {detailLoading && (
              <Form.Item
                wrapperCol={{ ...defaultFormLayout.wrapperCol, offset: 6 }}
              >
                <div className="hk-form-status">
                  <div className="hk-form-status-attributes-loading">
                    <Spin
                      tip="Loading product model attributes"
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  </div>
                </div>
              </Form.Item>
            )}
            <Form.Item
              wrapperCol={{ ...defaultFormLayout.wrapperCol, offset: 6 }}
            >
              <input hidden id="submit-form" type="submit" className="hidden" />
            </Form.Item>
          </AdminForm>
        )}
      </div>
      <Modal
        width={'862px'}
        visible={isModalOpen}
        footer={null}
        onCancel={handleModalCancel}
        maskClosable={false}
        destroyOnClose={true}
      >
        <ProductDetailModelModal onAddModelSubmit={handleModalSubmit} />
      </Modal>
    </>
  );
};

export default ProductDetail;
