import { addOptionalURLParams, createApiRequest } from '../../core/util/api';
import {
  fetchAppointmentListError,
  fetchAppointmentListStart,
  fetchAppointmentListSuccess,
} from './AppointmentListSlice';
import {
  APPOINTMENT_LIST_URL,
} from '../../core/util/constants';
import { RequestParams } from '../../core/types';

export const fetchHomeAppointments = (id: string, params: RequestParams) => createApiRequest({
  route: addOptionalURLParams(APPOINTMENT_LIST_URL, params),
  replace: ['home_id', id],
  // returnFullPayload: true,
  onStart: fetchAppointmentListStart,
  onSuccess: fetchAppointmentListSuccess,
  onError: fetchAppointmentListError,
  defaultErrorMessage: 'There was an error requesting appointments for this home.'
})();