import { Task } from '../../types';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import './StatusTags.scss';
import { Button } from 'antd';

type StatusTagsProps = {
  task: Task;
  editable: boolean;
  onToggleTag: (id: number) => void;
};

const StatusTags: React.FC<StatusTagsProps> = ({task, editable, onToggleTag}) => {

  const { statusTags } = useSelector((state: RootState) => state.appointmentDetail);

  const isSelected = (id: number): boolean => {
    return !!task.tag_ids?.includes(id);
  }

  return (
    <div className="hk-status-tags">
      <h4>Tags</h4>
      <div className="hk-status-tags-list">
        { statusTags?.map( (tag, index) => {
          return (
            <Button
              key={`status-tag-button-${index}`}
              className={`hk-status-tag-button level-${tag.level} ${isSelected(tag.id) ? 'selected' : ''}`}
              disabled={!editable}
              onClick={()=>onToggleTag(tag.id)}>
              { tag.name }
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default StatusTags;