import { addOptionalURLParams, createApiRequest } from '../../core/util/api';
import {
  PRODUCT_MODEL_DETAIL_URL,
  PRODUCT_MODEL_LIST_URL,
} from '../../core/util/constants';
import {
  deleteModelError,
  deleteModelSuccess,
  searchProductModelsError,
  searchProductModelsStart,
  searchProductModelsSuccess,
} from './ProductModelListSlice';
import { RequestParams } from '../../core/types';

export const searchProductModels = (params: RequestParams) => createApiRequest({
  route: addOptionalURLParams(PRODUCT_MODEL_LIST_URL, params),
  // returnFullPayload: true,
  onStart: searchProductModelsStart,
  onSuccess: searchProductModelsSuccess,
  onError: searchProductModelsError,
  defaultErrorMessage: 'There was an error fetching the product models.'
})();

export const deleteProductModel = (id: number) => createApiRequest({
  route: PRODUCT_MODEL_DETAIL_URL,
  replace: ['model_id', id],
  method: 'delete',
  onSuccess: deleteModelSuccess,
  onError: deleteModelError,
  defaultErrorMessage: 'There was an error deleting the product models.'
})();
