import { Manufacturer, PagedResults } from '../../core/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PagedManufacturerResults extends PagedResults {
  items: Manufacturer[]
}

interface ManufacturerState {
  manufacturersLoading: boolean;
  error: string | null;
  manufacturerList: Manufacturer[] | null;
  manufacturer: Manufacturer | null;
}

export const initialState: ManufacturerState = {
  manufacturersLoading: false,
  error: null,
  manufacturerList: null,
  manufacturer: null
};

export const manufacturersSlice = createSlice({
  name: 'manufacturers',
  initialState,
  reducers: {
    searchManufacturersSuccess: (state, action: PayloadAction<PagedManufacturerResults>) => {
      state.manufacturerList = action.payload.items;
      state.manufacturersLoading = false;
    },
    searchManufacturersError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.manufacturersLoading = false;
    },
    resetManufacturerList: (state) => {
      state.manufacturerList = null
      state.manufacturersLoading = false;
    },
    fetchManufacturerListStart: (state) => {
      state.manufacturersLoading = true;
    },
    fetchManufacturerListSuccess: (state, action: PayloadAction<PagedManufacturerResults>) => {
      state.manufacturerList = action.payload.items;
      state.manufacturersLoading = false;
    },
    fetchManufacturerListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.manufacturersLoading = false;
    },
    fetchManufacturerDetailsStart: (state) => {
      state.manufacturersLoading = true;
    },
    fetchManufacturerDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.manufacturer = action.payload;
      state.manufacturersLoading = false;
    },
    fetchManufacturerDetailsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.manufacturer = null;
      state.manufacturersLoading = false;
    }
  }
});

export const {
  searchManufacturersSuccess,
  searchManufacturersError,
  fetchManufacturerListStart,
  fetchManufacturerListSuccess,
  fetchManufacturerListError,
  resetManufacturerList,
  fetchManufacturerDetailsStart,
  fetchManufacturerDetailsSuccess,
  fetchManufacturerDetailsError
} = manufacturersSlice.actions;

export default manufacturersSlice.reducer;
