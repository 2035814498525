import * as React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store';
import { useEffect } from 'react';
import { FormField, FormFieldControl, getFormControl } from '../../core/util/form-helpers';
import { Button, Form, Spin } from 'antd';
import { loginUser } from './LoginActions';
import { HKActionType } from '../../core/types';
import { AUTH_TOKEN_KEY, EMAIL_REGEX } from '../../core/util/constants';
import Logo from 'assets/illustrations/hk-logo-light.svg'
import './Login.scss';

const Login: React.FC = () => {
  const { loading, error, accessToken, loggedIn } = useSelector((state: RootState) => state.login);
  const [ form ] = Form.useForm();
  const { getFieldsError } = form;
  const dispatch = useDispatch();
  const history = useHistory();

  const parseFormValuesToJSONBody = (values: any): object => {
    const { email, password } = values;
    return {
      email: email,
      password: password,
    };
  }

  const valuesChanged = (changedValues: object, allValues: object) => {
    // console.log(`${name}: ${value}`)
  }

  const onFinish = (values: any) => {
    const validationErrors = Object.values(getFieldsError());
    if (validationErrors.some(e => e.errors.length > 0)) {
      return;
    }
    dispatch(loginUser(parseFormValuesToJSONBody(values)));
  };

  useEffect(() => {
    if (loggedIn && !!accessToken) {
      localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
      history.push('/homes');
    }
  }, [loggedIn, accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const loginFields: FormField[] = [
    { name: 'email', label: 'Email', placeholder: 'Enter email', control: FormFieldControl.Input, rules: [{ required: true, message: 'Invalid email', pattern: EMAIL_REGEX  }]},
    { name: 'password', label: 'Password', placeholder: 'Enter email', control: FormFieldControl.InputPassword, rules: [{ required: true, message: 'Missing password' }] },
  ];

  return (
    <div className="hk-login">
      <img className="hk-logo" src={ Logo } alt="Logo" />
      <Form
        form={form}
        layout="vertical"
        className="hk-form confirm dark"
        onValuesChange={ valuesChanged }
        onFinish={ onFinish }
        autoComplete="off">
        <h1>Welcome <b>Back</b></h1>
        <h6>Enter your details below.</h6>
        { loading && <Spin className="hk-spinner" size="large"/> }
        { !loading &&
        <>
          {loginFields.map(field => { return getFormControl(field, HKActionType.Add, {}); })}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{width: '300px', margin: '24px 0'}}>
                Sign In
              </Button>
            </Form.Item>
        </>
        }
        {!!error &&
          <div className="hk-login-error">{ error }</div>}
      </Form>
    </div>
  );
};

export default Login;
